<template>
<Dialog v-model:visible="visible" maximizable modal header="프로젝트 정보 등록" :style="{ width: modalWidth }" @update:visible="$emit('closesModal'); visible = false;">
	  <div class="flex flex-wrap justify-content-left gap-2 mb-2">
      	<Divider align="left" type="solid">
	    	<b>고객사</b>
		</Divider>
		<p>
		    <AutoComplete v-model="company" optionLabel="companyName" dropdown :suggestions="filteredCompanys" @complete="companySearch" @change="setProjectList"/>
		</p>
		<Divider align="left" type="solid">
	    	<b>프로젝트명</b>
		</Divider>
		<p/>
			<div class="d-flex">
				<input type="text" class="form-control" v-model="projectName" maxlength="50" />
				<Button type="button" icon="pi pi-list" label="조회" @click="viewProjectsToggle" size="small" style="width: 100px;"/>
			</div>
			<OverlayPanel ref="op">
			     <Listbox v-model="projectList" disabled :options="projects" optionLabel="label" class="w-full md:w-14rem" />
			</OverlayPanel>	
		<!-- <Divider align="left" type="solid">
	    	<b>도입년도</b>
		</Divider>
		<p>
		    <InputText maxlength="4" v-model="year" style="width: 100px"/>
		</p>	     -->
		<Divider align="left" type="solid">
	    	<b>납품제품</b>
		</Divider>
		<p>
			<MultiSelect v-model="deliveryProd" display="chip" :options="deliveryProds" optionLabel="label" placeholder="제품 선택" filter :maxSelectedLabels="5" class="w-full md:w-20rem"/>
		</p>
		<Divider align="left" type="solid">
	    	<b>번들사 여부</b>
		</Divider>
		<p>
		    <input type="radio" v-model="partnerYn" value="Y" id="partnerYnY"/>
	        <label for="partnerYnY">있음</label>
	        <input type="radio" v-model="partnerYn" value="N" id="partnerYnN" @click="this.partnerName = ''"/>
	        <label for="partnerYnN">없음</label>
		</p>
		<Divider align="left" type="solid" v-if="this.partnerYn==='Y'">
	    	<b>번들사</b>
		</Divider>
		<p v-if="this.partnerYn==='Y'">
		    <input type="text" class="form-control" v-model="partnerName" maxlength="50" />
		</p>
		<Divider align="left" type="solid">
	    	<b>영업대표</b>
		</Divider>
		<p>
		    <AutoComplete v-model="salesRepresentative" optionLabel="name" dropdown :suggestions="filteredSalesRepresentativeEmails" @complete="salesRepresentativeSearch" />
		</p>
		<Divider align="left" type="solid">
			<b>비고</b>
		</Divider>
		<p>			
			<Textarea v-model="etc" variant="filled" rows="2" cols="70" maxlength="500" style="width: 100%" />
		</p>
	  </div>
      <template #footer>
        <Button label="취소" icon="pi pi-times" @click="$emit('closesModal'); visible = false;" text />
        <Button label="등록" icon="pi pi-check" @click="registProjectinfo" autofocus />
    </template>
  </Dialog>
</template>

<script>
import _ from "lodash";
export default {
  name: 'ProjectInfoRegistModal',
  data(){
    return{
      companys: [],
      projects: [],
      filteredCompanys: [],
      deliveryProds: [],
      filteredDeliveryProds: [],
      salesRepresentativeEmails: [],
      filteredSalesRepresentativeEmails: [],
      projectList: "",
      company: "",
      deliveryProd: "",
      salesRepresentative: "",
      projectName: "",
      year: "",
      partnerYn: "N",
      partnerName: "",
	  etc: "",
      visible: true,
      modalWidth: this.$isMobile() ? '100vw' : '35vw',
    }
  },
  props: {
  },
  components: {
  },
  created(){
  
    var param = {
    };
    
    this.$get('/biz/company/list',  {
	            	params: param
	       		 },
                function(response, i){
                   	i.companys = response.data.result;   //기존데이터에 추가
                },
                function(e, i){
                	console.log(e);
                }
     );
	var deliveryProds = [];
	var name = this.$store.getters.getName;

    this.$code(["PROD_CODE"], false,
		function(result, i){
			//i.deliveryProds = result.prodCode.filter(item => item.codeSort < 10 || item.codeSort >= 1000);
			i.deliveryProds = result.prodCode.filter(item => item.codeSort < 20 || item.codeSort >= 1000);
        },
        function(e, i){
        	console.log(e);
        }
	);
	
	this.$get('/biz/project/sr-list',  {
	            	params: param
	       		 },
                function(response, i){
                	i.salesRepresentativeEmails = response.data.result;   //기존데이터에 추가
                },
                function(e, i){
                	console.log(e);
                }
     );
     
     const current = new Date();
     this.year = current.getFullYear();
	
	
  },
  methods: {
    registProjectinfo() {
    	var checked = false;
		var checked2 = false;
    	var deliveryProdCodes = [];
		const isIncluded = this.projectName && this.projects.some(project => project.projectName.includes(this.projectName));
	 		
	  if(confirm("프로젝트 정보를 등록하시겠습니까?")){
		if(isIncluded){
			if(confirm("미리 생성된 유사한 프로젝트가 있는데 정말 진행 하시겠습니까???")){
				checked2 = true;
			}
		} else {
			checked2 = true;
		}

		if(checked2) {
			if(this.partnerYn == 'Y'){
      			if(this.company == "") {
      				this.$messageShow('고객사명은 필수입력항목입니다.', 'warn');
      			}else if(this.projectName == "") {
      				this.$messageShow('프로젝트명은 필수입력항목입니다.', 'warn');
      			}/*else if(this.year == "") {
      				this.$messageShow('도입년도는 필수입력항목입니다.', 'warn');
      			}*/else if(this.partnerName == "") {
			        this.$messageShow('번들사명은 필수입력항목입니다.', 'warn');
		          	return checked;
		        }else if(this.salesRepresentative == "") {
			        this.$messageShow('영업대표명은 필수입력항목입니다.', 'warn');
		          	return checked;
		        }else{
		        	checked = true;
		        }
      		}else{
      			if(this.company == "") {
      				this.$messageShow('고객사명은 필수입력항목입니다.', 'warn');
      			}else if(this.projectName == "") {
      				this.$messageShow('프로젝트명은 필수입력항목입니다.', 'warn');
      			}else if(this.year == "") {
      				this.$messageShow('도입년도는 필수입력항목입니다.', 'warn');
      			}else if(this.salesRepresentative == "") {
			        this.$messageShow('영업대표명은 필수입력항목입니다.', 'warn');
		          	return checked;
		        }else{
		        	checked = true;
		        }
      		}
      		
      		if(this.company.companyName == undefined || this.company.companyName == 'undefined'){
	    	  	this.$messageShow('고객사명이 잘못 선택되었습니다.', 'warn');
	    	  	checked = false;
	    	}
	    	
	    	var regExp = /^(20[0-9]{2})$/i;
            if(!regExp.test(this.year)){
            	this.$messageShow("도입년도가 정확하지 않습니다.", 'warn');
      			return checked;
            }
      		
      		if(this.salesRepresentative.userEmail == undefined || this.salesRepresentative.userEmail == 'undefined'){
	    	  	this.$messageShow('영업대표명이 잘못 선택되었습니다', 'warn');
	    	  	checked = false;
	    	}
      		
      		if(this.deliveryProd != null && this.deliveryProd != 'undefined' && this.deliveryProd != ''){

	      		this.deliveryProd = _.uniqBy(this.deliveryProd, 'value');
	    	
		    	this.deliveryProd.forEach(function(prod, index) {
				  deliveryProdCodes.push(prod.value);
				});
				
				if(deliveryProdCodes.length < 1){
		      		this.$messageShow('납품제품은 한개이상 필수선택항목입니다.', 'warn');
		      		checked = false;
		      		return checked;
		      	}
				
      		}else{
      			this.$messageShow('납품제품은 한개이상 필수선택항목입니다.', 'warn');
      			checked = false;
      			return checked;
      		}
		}      		
	
      	if(checked){
			var param = {
	          "companySeq": this.company.companySeq ,
	          "projectName": this.projectName,
	          "year": this.year,
	          "deliveryProdCodes" : deliveryProdCodes,
	          "partnerYn" : this.partnerYn,
	          "partnerName" : this.partnerName,
	          "salesRepresentativeEmail" : this.salesRepresentative.userEmail,
	          "registerEmail" : this.$store.getters.getUserEmail,
			  "etc" : this.etc,
	        };
	        
	        this.$post('/biz/project/add', param,
		                function(response, i){
		                	i.$messageShow('등록이 완료되었습니다.', 'success');
							i.$emit('update');
		                	i.visible = false;
		                	i.$emit('closesModal');	          				
		                },
		                function(e, i){
		                	i.$messageShow(e, 'danger');
		                }
		 	);      	
      	}   	        
      }
    },
    deliveryProdSearch(event) {
             setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredDeliveryProds = [...this.deliveryProds];
                } else {
                    this.filteredDeliveryProds = this.deliveryProds.filter((deliveryProd) => {
                        return deliveryProd.label.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
            
            
    },
    companySearch(event) {
             setTimeout(() => {
                if (!event.query.trim().length) {
					this.filteredCompanys = this.companys.filter((company) => company.useYn === 'Y');
                } else {
                    this.filteredCompanys = this.companys.filter((company) => {
						return company.useYn === 'Y' && company.companyName.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
            
            
    },
    salesRepresentativeSearch(event) {
             setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredSalesRepresentativeEmails = [...this.salesRepresentativeEmails];
                } else {
                    this.filteredSalesRepresentativeEmails = this.salesRepresentativeEmails.filter((salesRepresentative) => {
                        return salesRepresentative.name.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
            
    },
    setProjectList() {
    	if(this.company.companySeq != undefined){
			var param = {
				companySeq : this.company.companySeq,
			};
	    
			this.$get('/biz/project/list',  {
			            	params: param
				 },
			        function(response, i){
						i.projects = response.data.result.filter((project) => project.useYn === 'Y');   //기존데이터에 추가
			        },
			        function(e, i){
			        	console.log(e);
			        }
			);
		}
            
    },
    viewProjectsToggle(event) {
		this.$refs.op.toggle(event);
    },	
	selectAllChange(event) {			
		if(event.value){
			this.deliveryProd = this.deliveryProds;	
		}else{
			this.deliveryProd = [];
		}
	}
  },
}
</script>

<style scoped>
.black-bg {
  width: 100%; 
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  padding: 20px;
}
.white-bg {
  width: 400px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  transform: translate(-50%, 5%);
}
h4 {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(169, 168, 168);
}
.check {
  margin-left: 40px;
}
</style>

