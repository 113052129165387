<template>
	<Dialog v-model:visible="visible" maximizable :closeOnEscape=false modal header="기술지원 이력 작성" :style="dialogStyle" :contentStyle="dialogContent" @update:visible="$emit('closesModal'); visible = false;">
		<TabView  v-model:activeIndex="active">
			<TabPanel header="기본정보" disabled=true>
				<Divider align="left" type="solid">
					<b>고객사</b>
				</Divider>
				<p>
					<AutoComplete v-model="company" optionLabel="companyName" dropdown :suggestions="filteredCompanys" @complete="companySearch" @change="setProjectList" style="width: 100%;"/>
				</p>
				
				<Divider align="left" type="solid">
					<b>프로젝트</b>
				</Divider>
				<p>
					<AutoComplete v-model="project" optionLabel="label" dropdown :suggestions="filteredProjects" @complete="projectSearch" @change="setProjectInfo"  style="width: 100%;"/>
				</p>			
				
				<Divider align="left" type="solid">
					<b>요청주체</b>
				</Divider>
				<p>
					<Dropdown v-model="entity" scrollHeight= "500px" :options="entitys" optionLabel="label" placeholder="요청주체 선택" class="w-full md:w-14rem" />
				</p>

				<Divider align="left" type="solid">
					<b>지원방법</b>
				</Divider>
				<p>
					<Dropdown v-model="supportCategory" scrollHeight= "500px" :options="supportCategorys" @change="changeSupportCategory" optionLabel="label" placeholder="지원방법 선택" class="w-full md:w-14rem" />
				</p>
	
				
				<Divider align="left" type="solid">
					<b>지원일정</b>
				</Divider>				
				<Calendar v-model="visitDate" dateFormat="yy/mm/dd" @date-select="inputDate" showIcon showButtonBar style="max-width: 170px;" :showOnFocus="false" />
				
				<span v-if="supportCategory.code == 'ONSITE_SUPPORT'">
					- <Calendar v-model="visitEndDate" dateFormat="yy/mm/dd" showIcon showButtonBar style="max-width: 170px;" :showOnFocus="false" />
				</span>				
				
				<Divider align="left" type="solid">
					<b>지역구분</b>
				</Divider>
				<p>
					<Dropdown v-model="region" scrollHeight= "500px" :options="regions" optionLabel="label" placeholder="지역구분 선택" @change="changeRegion" class="w-full md:w-14rem" />
				</p>	

				<div v-if="supportCategory.code == 'ONSITE_SUPPORT' || supportCategory.code == 'OFFSITE_SUPPORT'">
					<Divider align="left" type="solid">
						<b>작업자</b>
					</Divider>
					<p>			
						<MultiSelect scrollHeight= "500px" v-model="supportRows[0].supportName" display="chip" :options="supportNames"  optionLabel="name" placeholder="선택" filter :maxSelectedLabels="2" class="w-full md:w-14rem" style="width: 160px;" />
					</p>
				</div>

				<Divider align="left" type="solid">
					<b>요청내용</b>
				</Divider>
				<p>			
					<Textarea v-model="requestDetails" ref="requestDetails" variant="filled" rows="8" cols="70" maxlength="2000" @keydown="requestDetailsCopy"  
					v-tooltip.top="{
						value: phRequestDetails,						
						pt: {
							text: {
								style: {              
									Width: '400px', // 툴팁의 최대 너비를 300px로 지정              
								}
							}
						}
					}" 
					:placeholder="phRequestDetails" style="width: 100%" />							
				</p>

				<div v-if="supportCategory.code == 'OFFSITE_SUPPORT'" >
					<Divider align="left" type="solid">
						<b>지원내용</b>
					</Divider>
					<p>			
						<Textarea v-model="supportRows[0].supportDetail" variant="filled" rows="8" cols="70" maxlength="2000" style="width: 100%" />
					</p>

				</div>
				
			</TabPanel>
			<TabPanel header="상세" disabled=true>	
				<div v-if="isMobile == false">
					<!-- 본문 -->				    
					<table class="table table-bordered table-hover caption-top">
						<thead class="table-light">
							<tr class="table-primary">
								<th scope="col" v-for="item in header" :key="item" class="center">
									{{ item }}								
									<Button v-if="item === '서버구분'" label="과거버전" severity="warning" @click="checkVersionHistory" style="height: 25px;width: 75px;margin: 0;padding: 0px;" />
								</th>
							</tr>
						</thead>
						<tbody class="table-group-divider">						
							<tr v-for="row in supportRows" :key="row">						
								<td class="center"><Dropdown scrollHeight= "500px" v-model="row.supportCategoryDetail" @change="changeSupportCategoryDetail(row)" :options="supportCategoryDetails" optionLabel="label" placeholder="선택" class="w-full md:w-14rem" style="width: 120px;" /></td>						
								<td class="center"><MultiSelect scrollHeight= "500px" v-model="row.supportName" display="chip" :options="supportNames"  optionLabel="name" placeholder="선택" filter :maxSelectedLabels="2" class="w-full md:w-14rem" style="width: 160px;" /></td>						
								<td class="center">
									<Dropdown scrollHeight= "500px" v-model="row.supportProd" @change="changeSupportProd" :options="supportProds" optionLabel="label" placeholder="제품 선택"  class="w-full md:w-14rem" style="width: 145px;" />
									<!-- <MultiSelect v-model="row.supportProd" display="chip" :options="supportProds" optionLabel="label" placeholder="제품 선택" filter :maxSelectedLabels="5" class="w-full md:w-20rem" style="width: 145px;"/> -->
								</td>
								<td class="center"><Dropdown scrollHeight= "500px" v-model="row.serverType" :options="serverTypes" optionLabel="label" placeholder="선택" :disabled="row.serverTypeDisabled" class="w-full md:w-14rem" style="width: 175px;" /></td>
								<!-- <td class="center"><Dropdown scrollHeight= "500px" v-model="row.versionCategory" :options="versionCategorys" optionLabel="label" placeholder="선택" :disabled="row.versionCategoryDisabled" class="w-full md:w-14rem" style="width: 175px;" /></td>
								<td class="center"><InputText type="text" v-model="row.version" :disabled="row.versionDisabled" /></td> -->
								<td class="center"><InputText type="text" v-model="row.versionArr[0]" :disabled="row.versionDisabled" style="width:100%" /></td>
								<td class="center"><InputText type="text" v-model="row.versionArr[1]" :disabled="row.versionDisabled" style="width:100%" /></td>
								<td class="center"><InputText type="text" v-model="row.versionArr[2]" :disabled="row.versionDisabled" style="width:100%" /></td>
								<td class="center"><InputText type="text" v-model="row.versionArr[3]" :disabled="row.versionDisabled" v-tooltip.bottom="{ value: 'ex) exePrint, RexESP', autoHide: false }" style="width:100%" /></td>

								<!-- 설치문서 업로드 -->
								<!-- <td class="center">
									<label for="uploadFile" style="display:none"><input type="file" :id="'uploadFile_'+row.id" accpet="*" @change="setFileUpload(row)" style="width:100%" ></label>								
									<Button type="button" icon="pi pi-paperclip" @click="clickFileInput(row.id)" :disabled="row.supportDetailsInstallDocDisabled" style="background-color: black;border:black;" />    															
								</td> -->
								
								<!-- 설치문서 URL -->
								<td class="center"><InputText type="text" v-model="row.supportDetailsInstallDoc" placeholder="설치문서 URL" :disabled="row.supportDetailsInstallDocDisabled" style="width:100%" /></td>

								<td class="center"><Button icon="pi pi-pencil" severity="warning" @click="row.supportDetailVisible = true, responseDetail2 = row.supportDetail;" /></td>
								<td class="center"><Dropdown scrollHeight= "500px" v-model="row.supportStatus" :options="supportStatuses" optionLabel="label" placeholder="선택" class="w-full md:w-14rem" style="width: 120px;" /></td>
								<td class="center"><Button label="Remove" outlined @click="removeRow(row)" /></td>						
								<Dialog v-model:visible="row.supportDetailVisible" modal header="지원내용" @show="responseSaved = false" @hide="onResCloseDialog(row)" :style="{ width: '50rem' }">
									<Textarea v-model="row.supportDetail" variant="filled" rows="10" cols="70" maxlength="500" style="width: 100%" />	
									<template #footer>		
										<Button label="저장" icon="pi pi-check" @click="onSaveResponse(row)" autofocus />
									</template>
								</Dialog>
							</tr>
							<Dialog v-model:visible="versionHistoryVisible" modal header="과거 버전" @show="versionHistorySaved = false" :style="{ width: '50rem' }">
								<!-- <Textarea v-model="row.supportDetail" variant="filled" rows="10" cols="70" maxlength="500" style="width: 100%" />	 -->
								<table class="table table-bordered table-hover caption-top">
									<thead class="table-light">
										<tr class="table-primary">
											<th scope="col" v-for="item in header2" :key="item" class="center" >
												{{ item }}
											</th>
										</tr>
									</thead>
									<tbody class="table-group-divider">
										<tr v-for="(row, i) in versionHistory" :key="i">
											<td class="center">{{ row.visitDate }}</td>
											<td class="center">{{ row.serverType.label }}</td>
											<td class="center">{{ row.serverEngineVer }}</td>
											<td class="center">{{ row.viewerVer }}</td>
											<td class="center">{{ row.designerVer }}</td>
											<td class="center">{{ row.moduleVer }}</td>
											<td class="center"><Button label="선택" @click="setVersionHistory(row)" style="height: 25px;width: 75px;margin: 0;padding: 0px;" /></td>
										</tr>
									</tbody>
								</table>
							</Dialog>												
						</tbody>
					</table>
				</div>
				<div v-if="isMobile">					
					<Accordion class="accordion-custom" :activeIndex="0">
						<AccordionTab v-for="(row, i) in supportRows" :key="i">
							<template #header>
								<div class="header-container">
									<B> {{ (i+1)+'번째 이력' }}</B>
									<!-- <Button label="삭제" @click="removeRow(row)" />	 -->
									<Button icon="pi pi-trash" severity="info" @click="removeRow(row)" style="width:32px;height: 32px;" />
									
								</div>
							</template>													
							<tr class="mobileCss_tr">
								<td class="mobileCss_td"><b>{{ header[0] }}</b></td><td class="mobileCss_td">
									<Dropdown scrollHeight= "500px" v-model="row.supportCategoryDetail" @change="changeSupportCategoryDetail(row)" :options="supportCategoryDetails" optionLabel="label" placeholder="선택" class="w-full md:w-14rem" style="width: 120px;" />
								</td>
							</tr>
							<tr class="mobileCss_tr">
								<td class="mobileCss_td"><b>{{ header[1] }}</b></td><td class="mobileCss_td">
									<MultiSelect scrollHeight= "500px" v-model="row.supportName" display="chip" :options="supportNames"  optionLabel="name" placeholder="선택" filter :maxSelectedLabels="2" class="w-full md:w-14rem" style="width: 160px;" />
								</td>
							</tr>
							<tr class="mobileCss_tr">
								<td class="mobileCss_td"><b>{{ header[2] }}</b></td><td class="mobileCss_td">									
									<Dropdown scrollHeight= "500px" v-model="row.supportProd" @change="changeSupportProd" :options="supportProds" optionLabel="label" placeholder="제품 선택"  class="w-full md:w-14rem" style="width: 145px;" />
								</td>
							</tr>
							<tr class="mobileCss_tr">
								<td class="mobileCss_td"><b>{{ header[3] }}</b></td><td class="mobileCss_td">									
									<Dropdown scrollHeight= "500px" v-model="row.serverType" :options="serverTypes" optionLabel="label" placeholder="선택" :disabled="row.serverTypeDisabled" class="w-full md:w-14rem" style="width: 175px;" />
								</td>
							</tr>
							<tr class="mobileCss_tr">
								<td class="mobileCss_td"><b>{{ header[4] }}</b></td><td class="mobileCss_td">									
									<InputText type="text" v-model="row.versionArr[0]" :disabled="row.versionDisabled" style="width:100%" />
								</td>
							</tr>
							<tr class="mobileCss_tr">
								<td class="mobileCss_td"><b>{{ header[5] }}</b></td><td class="mobileCss_td">									
									<InputText type="text" v-model="row.versionArr[1]" :disabled="row.versionDisabled" style="width:100%" />
								</td>
							</tr>
							<tr class="mobileCss_tr">
								<td class="mobileCss_td"><b>{{ header[6] }}</b></td><td class="mobileCss_td">									
									<InputText type="text" v-model="row.versionArr[2]" :disabled="row.versionDisabled" v-tooltip.bottom="{ value: 'ex) exePrint, RexESP', autoHide: false }" style="width:100%" />
								</td>
							</tr>
							<tr class="mobileCss_tr">
								<td class="mobileCss_td"><b>{{ header[7] }}</b></td><td class="mobileCss_td">									
									<InputText type="text" v-model="row.versionArr[3]" :disabled="row.versionDisabled" v-tooltip.bottom="{ value: 'ex) exePrint, RexESP', autoHide: false }" style="width:100%" />
								</td>
							</tr>
							<tr class="mobileCss_tr">
								<td class="mobileCss_td"><b>{{ header[8] }}</b></td><td class="mobileCss_td">
									<InputText type="text" v-model="row.supportDetailsInstallDoc" placeholder="설치문서 URL" :disabled="row.supportDetailsInstallDocDisabled" style="width:100%" />									
								</td>
							</tr>
							<tr class="mobileCss_tr">
								<td class="mobileCss_td"><b>{{ header[9] }}</b></td><td class="mobileCss_td">	
									<Button icon="pi pi-pencil" severity="warning" @click="row.supportDetailVisible = true, responseDetail2 = row.supportDetail;" />
								</td>
							</tr>
							<tr class="mobileCss_tr">
								<td class="mobileCss_td"><b>{{ header[10] }}</b></td><td class="mobileCss_td">	
									<Dropdown scrollHeight= "500px" v-model="row.supportStatus" :options="supportStatuses" optionLabel="label" placeholder="선택" class="w-full md:w-14rem" style="width: 120px;" />								
								</td>
							</tr>
							<Dialog v-model:visible="row.supportDetailVisible" modal header="지원내용" @show="responseSaved = false" @hide="onResCloseDialog(row)" :style="{ width: '50rem' }">
								<Textarea v-model="row.supportDetail" variant="filled" rows="10" cols="70" maxlength="500" style="width: 100%" />	
								<template #footer>		
									<Button label="저장" icon="pi pi-check" @click="onSaveResponse(row)" autofocus />
								</template>
							</Dialog>
						</AccordionTab>
					</Accordion>
				</div>
				<p style="margin-top: 1em;">
					<Button label="지원 추가" severity="secondary" rounded @click="addRow" />
					<Button label="지원 추가(마지막 값 복붙)" id="BtnSupportCopyAndPaste" severity="secondary" rounded @click="addRow('Y')" />
				</p>
			</TabPanel>
		</TabView>
		<template #footer>
			<div style = 'padding: 0.5rem 0rem 0rem 0rem;'>
				<Button label="다음" icon="pi pi-arrow-circle-right" v-if="active == 0 && (supportCategory.code != 'ONSITE_SUPPORT' && supportCategory.code != 'OFFSITE_SUPPORT')" @click="nextStep" autofocus/>
				<Button label="이전" icon="pi pi-arrow-circle-left" v-if="active == 1 && (supportCategory.code != 'ONSITE_SUPPORT' && supportCategory.code != 'OFFSITE_SUPPORT')" @click="nextStep" />
				<Button label="취소" icon="pi pi-times" @click="$emit('update'); $emit('closesModal'); visible = false;" text />		
				<Button label="저장" icon="pi pi-check" v-if="active == 1 || (supportCategory.code == 'ONSITE_SUPPORT' || supportCategory.code == 'OFFSITE_SUPPORT')" @click="regist" text />
			</div>
		</template>
	</Dialog>
	
	</template>
	
	<script>
	import axios from 'axios';
	export default {
	  name: 'SupportRegistModal',
	  data(){
		return{
		  companys: [],
		  filteredCompanys: [],            
		  projects: [],
		  filteredProjects: [],             
		  company: "",
		  project: "",            
		  requestDetails: "",            
		  visitDate: new Date(),    
		  visitEndDate: new Date(),    
		  region: '',  
		  regions: {},  
		  supportCategory: '',
		  supportCategorys: {},
		  supportCategorys_org: {},
		  serverTypes: {},
		  supportCategoryDetails: {},	  
		  versionCategorys: {},
		  visible: true,
		  active:0,
		  supportProds: {},	
		  supportStatuses: {},	
		  modalWidth: this.isMobile ? '150vw' : '35vw',   		  
		  //header: ["지원구분", "작업자", "제품", "서버구분", "버전구분", "버전", "설치문서", "지원내용", "지원상태", "삭제"],
		  header: ["지원구분", "작업자", "제품", "서버구분", "서버엔진(ver)", "뷰어(ver)", "디자이너(ver)", "모듈(ver)", "설치문서(url)", "지원내용", "지원상태", "삭제"],
		  header2: ["방문일자", "서버구분", "서버엔진(ver)", "뷰어(ver)", "디자이너(ver)", "모듈(ver)", "선택"],
		  checked: false,		
		  responseDetail2: '',
		  responseSaved: false,
		//   supportRows: [
		//       {supportCategoryDetail: "", supportName: "", supportProd: "", serverType: "", serverTypeDisabled: false, 
		// 	  versionCategory: "", versionCategoryDisabled: false , 
		// 	  version: "", versionDisabled: false , 
		// 	  supportDetailsInstallDoc: "", supportDetailsInstallDocDisabled: false ,
		// 	   supportDetail: "", supportDetailVisible: false , supportStatus: ""}
		//     ],	
			supportRows: [
				{supportCategoryDetail: "", supportName: "", supportProd: "", serverType: "", serverTypeDisabled: false, 
				versionArr: [ "", "", "", ""] ,versionDisabled: false , 
				id: 0, supportDetailsInstallDoc: null, supportDetailsInstallDOCuuid: "", supportDetailsInstallDocDisabled: true ,
				supportDetail: "", supportDetailVisible: false , supportStatus: ""}
				],
			inputTagArray : [],
			supportNameMyname : [],
			versionHistory : [],
			versionHistoryVisible : false,
			versionHistorySaved: false,
			isSupportPeriod : false,
			phRequestDetails : '',
			loginUserName : '',
			dialogStyle : '',
			dialogContent : '',
			entitys: {},
			entity: '',
		}
	  },
	  props: {
		isMobile: Boolean,
		supportNames: Array,
	  },
	  components: {
	  },
	  created(){  	
		this.setDialogSize();
		
		var param = {
		};
		
		this.$get('/biz/company/list',  {
						params: param
						},
					function(response, i){
						   i.companys = response.data.result;   //기존데이터에 추가					
					},
					function(e, i){
						console.log(e);
					}
		 );
		
		this.$code(["SERVER_TYPE", "REGION", "SUPPORT_CATEGORY", "SUPPORT_CATEGORY_DETAIL", "VERSION_CATEGORY", "PROD_CODE", "SUPPORT_STATUS", "ENTITY"], false,
			function(result, i){           	    			          
			   i.serverTypes = result.serverType;			   
			   i.regions = result.region;
			   i.supportCategorys = result.supportCategory;   
			   i.supportCategorys_org = i.supportCategorys;   
			   i.supportCategoryDetails = result.supportCategoryDetail;
			   i.supportProds = result.prodCode;
			   i.versionCategorys = result.versionCategory;
			   i.supportStatuses = result.supportStatus;		
			   i.entitys = result.entity;
	
			   i.serverTypes.push({
					"code": "-",
					"codeName": "-",
					"label": "-",
					"value": "-",
					"codeSort": 0,
					"useYn": true
				});
				i.serverTypes.sort((a, b) => a.codeSort - b.codeSort);	   
			},
			function(e, i){
				console.log(e);
			}
		);		
	  },
	  mounted() {
			//달력(속성 변경)
			this.$calendar();
			var self = this;				
			this.supportNames.forEach(function(supportName, index) {															
				if(self.$store.getters.getUserEmail === supportName.userEmail){													
					self.supportNameMyname.push(supportName);																									
				}				
				self.supportRows[0].supportName = self.supportNameMyname;
			});						
			this.loginUserName = self.supportRows[0].supportName[0].name;			
	  },
	  methods: {
		changeSupportCategory(){			
			if(this.supportCategory.code == 'ONSITE_SUPPORT'){
				this.visitEndDate = this.visitEndDate == null ? this.visitDate : this.visitEndDate;				
				this.phRequestDetails = ""
				+"<예시>\n"
				+"as-is 제품 : RD 5.0\n"
				+"도입 제품 : CLIP report 5.0\n"
				+"본 수 : 500 본\n"
				+"\n"
				+"비고 : \n"
				+"- 껍데기만 개발\n"
				+"- 오픈 후 데이터 매핑으로 한달 간 주 1회 지원";

				this.supportRows[0].supportDetail = "";

			} else if(this.supportCategory.code == 'OFFSITE_SUPPORT'){
				this.phRequestDetails = ""
				+"<예시>\n"
				+"as-is 제품 : RD 5.0\n"
				+"도입 제품 : CLIP report 5.0\n"
				+"본 수 : 500 본\n"
				+"\n"
				+"비고 : \n"
				+"- 7본 개발( 7/500 )\n"
				+"- 껍데기만 개발";
			} else {
				this.visitEndDate = null;
				this.phRequestDetails = "";
				this.supportRows[0].supportDetail = "";
			}
		},
		nextStep(){					
			if ( this.active == 0) { //기본정보													
				if(this.company.companyName == undefined || this.project.projectName == undefined || this.entity == ""
				|| this.requestDetails == "" || this.region == "" || this.supportCategory == ""){
					this.$messageShow('값을 모두 입력 해주세요.', 'warn');
				} else if(this.isPreWritte()) {
					this.$messageShow('미리 작성 할 수 없습니다.\n(당일부터 작성가능)', 'warn');
				} else {					
					this.active = 1;
					this.modalWidth = this.$isMobile() ? '150vw' : '90%';		
					this.setDialogSize();
				}			
			} else { //상세
				this.active = 0;
				this.modalWidth = this.$isMobile() ? '150vw' : '35vw';
				this.setDialogSize();
			}
		},  
		isPreWritte() {			
			const current = new Date();
			const visitD = this.$simpleDateTimeFormat(this.visitDate, "yyyy/MM/dd");
			const todayD = this.$simpleDateTimeFormat(current, "yyyy/MM/dd");
			return visitD > todayD;
		},
		async regist() {    	
			var data = this.supportRows;
			// 변환된 JSON을 담을 배열
			var transformedData = [];
			var validationCheck = [];
			var self = this;			
			// 각 데이터를 변환하여 새로운 배열에 추가
			data.forEach(function(item, index) {					
				var supportNames = "";
				for(var i =0; i< item.supportName.length; i++){
					supportNames += item.supportName[i].name +",";
				}
	
				var versionArr = "";
				var versionCategorys = "";
	
				if(item.versionArr[0] != "") {
					versionArr += item.versionArr[0] + ",";
					versionCategorys += "서버엔진" + ",";
				} 
				if(item.versionArr[1] != "") {
					versionArr += item.versionArr[1] + ",";
					versionCategorys += "뷰어" + ",";
				}
				if(item.versionArr[2] != "") {
					versionArr += item.versionArr[2] + ",";
					versionCategorys += "디자이너" + ",";
				}
				if(item.versionArr[3] != "") {
					versionArr += item.versionArr[3] + ",";
					versionCategorys += "모듈" + ",";
				}
				
				var transformedItem = {
					"supportCategoryDetail": item.supportCategoryDetail.label,
					"supportNames": supportNames.slice(0, -1),
					"supportProd": item.supportProd.label,
					"serverType": item.serverType.label,
					"versionCategory": versionCategorys.slice(0, -1),
					"version": versionArr.slice(0, -1),
					"supportDetailsInstallDOC": item.supportDetailsInstallDoc,
					"supportDetailsInstallDOCuuid": item.supportDetailsInstallDOCuuid,
					"supportDetail": item.supportDetail,
					"supportStatus": item.supportStatus.label
				};
				transformedData.push(transformedItem);
				
				validationCheck.push(self.validationCheck(transformedItem, index+1));
			});		
			
			if(!validationCheck.includes(undefined)){				
				//설치문서 업로드
				//await this.uploadFiles(transformedData);

				var param = {
					//"company":this.company,
					"projectUid":this.project.projectUid,
					"requestDetails":this.requestDetails,
					"visitDate":this.$simpleDateTimeFormat(this.visitDate, "yyyy/MM/dd"),					
					"visitEndDate" : this.visitEndDate == null ? null : this.$simpleDateTimeFormat(this.visitEndDate, "yyyy/MM/dd"),
					"region":this.region.label,
					"supportCategory":this.supportCategory.label,
					"requestEntity":this.entity.code,
					"registerName" : this.loginUserName,					
					"supportCategoryDetailList": transformedData,
				}

				this.$post('/biz/support/add', param,
					function(response, i){
				 		i.$messageShow('등록이 완료되었습니다.', 'success');
							i.$emit('update');
							i.visible = false;
							i.$emit('closesModal');	   
				 	},
				 	function(e, i){
				 		i.$messageShow(e, 'danger');
					}
				);  
				
			}
					   
		},
		async uploadFiles(transformedData) {			
			try {				
				for (const item of transformedData) {
					if (item.supportCategoryDetail == "설치" && item.supportDetailsInstallDOC !== null) {
						const response = await axios.post('/biz/common/fileUpload', item.supportDetailsInstallDOC, {
							headers: {
								'Content-type': 'multipart/form-data'
							}
						});		
						this.$messageShow(response.data.split('\n')[0], 'warn');						
						item.supportDetailsInstallDOC = response.data.split('\n')[1];
						item.supportDetailsInstallDOCuuid = response.data.split('\n')[2];
					}
				}
			} catch (error) {
				("파일업로드 에러", error);
				this.$messageShow(error.response.data.errorMsg, 'error');
			}
		},
		setProjectList() {			
			this.partnerName = "";
			this.deliveryProdName = "";
			this.salesRepresentativeName = "";
			this.project = "";
			if(this.company.companySeq != undefined){
				var param = {
					companySeq : this.company.companySeq,
				};
			
				this.$get('/biz/project/list',  {
								params: param
					 },
						function(response, i){
							i.projects = response.data.result;   //기존데이터에 추가
						},
						function(e, i){
							console.log(e);
						}
				);
			}
				
		},
		companySearch(event) {		
				 setTimeout(() => {
					if (!event.query.trim().length) {
						this.filteredCompanys = this.companys.filter((company) => company.useYn === 'Y');			
					} else {
						this.filteredCompanys = this.companys.filter((company) => {
							return company.useYn === 'Y' && company.companyName.toLowerCase().includes(event.query.toLowerCase());
						});
					}
				}, 250);
				
				
		},
		projectSearch(event) {			
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredProjects = this.projects.filter((project) => project.useYn === 'Y');
				} else {
					this.filteredProjects = this.projects.filter((project) => {
						return project.useYn === 'Y' &&  project.projectName.toLowerCase().includes(event.query.toLowerCase());
					});
				}
			}, 250);            
		},
		setProjectInfo(){			
			const current = new Date();
			
			if(this.project.companySeq != undefined && this.project.projectUid != undefined ){
				var param = {
					companySeq : this.project.companySeq,
					projectUid : this.project.projectUid
				};
			  
				  this.$get(`/biz/project/get`, {
								params: param
								},
								function(response, i){									
									i.partnerName = response.data.detail.partnerName;
									i.deliveryProdName = response.data.detail.deliveryProdName;
									i.salesRepresentativeName = response.data.detail.salesRepresentativeName;
									i.partnerYn = response.data.detail.partnerYn;
									i.year = response.data.detail.year !== "" ? response.data.detail.year : current.getFullYear();									
								},
								function(e, i){
									console.log(e);
								}
				 );

				 this.$get(`/biz/support/getVersionHistory`,{
									params: {
										"pageNum": 1,
      									"pageSize": 10,	
										projectUid : this.project.projectUid,
									}
								},
								function(response, i){									
									i.versionHistory = response.data.paginationVerHis.list;
									i.serverTypes.forEach(function(serverType, index) {										
										for(var j=0; j<i.versionHistory.length; j++){
											if(i.versionHistory[j].serverType === serverType.label){
												i.versionHistory[j].serverType = serverType;
											}
										}										
									});	
								},
								function(e, i){
									console.log(e);
								}
				 );
			 }
		
		},    
		addRow(copyPasteYN){	
			if(this.supportRows.length > 4) {
				this.$messageShow('최대 지원 갯수는 5개입니다.', 'warn');				
			} else {
				if(this.validationCheck()){	
					if (copyPasteYN	!= "Y") {					
						this.supportRows.push({supportCategoryDetail: "", supportName: this.supportNameMyname, supportProd: "", serverType: "", serverTypeDisabled: false, 
						// versionCategory: "", versionCategoryDisabled: false , 
						// version: "", versionDisabled: false , 
						versionArr: [ "", "", "", "" ] ,versionDisabled: false ,					
						id: this.supportRows.length == 0 ? 0 : this.supportRows[this.supportRows.length-1].id+1 ,supportDetailsInstallDoc: null, supportDetailsInstallDOCuuid: "", supportDetailsInstallDocDisabled: false ,
						supportDetail: "", supportDetailVisible: false , supportStatus: ""});
						this.checked=false;	

						if(this.supportRows.length == 1) {				
							document.getElementById("BtnSupportCopyAndPaste").disabled = false;
						}	

					} else { //지원추가 복붙	
						var last_supportRows = this.supportRows[this.supportRows.length-1];													
						this.supportRows.push({
							supportCategoryDetail: last_supportRows.supportCategoryDetail,
							supportName: last_supportRows.supportName, 
							supportProd: last_supportRows.supportProd, 
							serverType: last_supportRows.serverType, serverTypeDisabled: last_supportRows.serverTypeDisabled ,
							// versionCategory: last_supportRows.versionCategory, versionCategoryDisabled: last_supportRows.versionCategoryDisabled ,
							// version: last_supportRows.version, versionDisabled: last_supportRows.versionDisabled , 
							versionArr: [ last_supportRows.versionArr[0], last_supportRows.versionArr[1], last_supportRows.versionArr[2], last_supportRows.versionArr[3]] ,versionDisabled: last_supportRows.versionDisabled ,
							id: last_supportRows.id+1 ,supportDetailsInstallDoc: last_supportRows.supportDetailsInstallDoc,  supportDetailsInstallDOCuuid: "", supportDetailsInstallDocDisabled: last_supportRows.supportDetailsInstallDocDisabled , 
							supportDetail: "", supportDetailVisible: last_supportRows.supportDetailVisible ,
								supportStatus: last_supportRows.supportStatus});	
						this.checked=false;		
					}
				}
			}
		},	
		removeRow: function(row){
			if(this.supportRows.length == 1) {				
				document.getElementById("BtnSupportCopyAndPaste").disabled = true;
			}
		  this.supportRows.splice(this.supportRows.indexOf(row), 1);
		},	
		changeSupportCategoryDetail(row){
			const supportName = row.supportCategoryDetail.codeName;				
			if(supportName == "교육" || supportName == "미팅"){
				row.serverTypeDisabled = true;			
				row.versionCategoryDisabled = true;			
				row.versionDisabled = true;			
				row.supportDetailsInstallDocDisabled = true;			
				row.serverType = "";
				// row.versionCategory = "";
				// row.version = "";
				row.versionArr = [ "", "", "", "" ];
				row.supportDetailsInstallDoc = "";
			} else if(supportName == "기술지원" || supportName == "기능패치" || supportName == "버그패치"){
				row.serverTypeDisabled = false;
				row.versionCategoryDisabled = false;
				row.versionDisabled = false;
				row.supportDetailsInstallDocDisabled = true;
				row.supportDetailsInstallDoc = "";
			} else {
				row.serverTypeDisabled = false;
				row.versionCategoryDisabled = false;
				row.versionDisabled = false;
				row.supportDetailsInstallDocDisabled = false;
			}
		},
		changeRegion(event){
			if(event.value.codeName == "본사"){				
				this.supportCategorys = this.supportCategorys_org.filter(item => item.codeSort > 1);
			} else {				
				this.supportCategorys = this.supportCategorys_org.filter(item => item.codeSort > 0);
			}
		},
		validationCheck(supportRows, itemIndex){			
			if (this.supportCategory.code == 'ONSITE_SUPPORT' || this.supportCategory.code == 'OFFSITE_SUPPORT') {				
				if(this.company.companyName == undefined || this.project.projectName == undefined
				|| this.requestDetails == "" || this.region == "" || this.supportCategory == "" || supportRows.supportNames == ""){
					this.$messageShow('값을 모두 입력 해주세요.', 'warn');
				} else if(this.isPreWritte()) {
					this.$messageShow('지원 일정은 당일부터 작성 할 수 있습니다.', 'warn');
				} else if(this.supportCategory.code == 'OFFSITE_SUPPORT' && supportRows.supportDetail == ""){
					this.$messageShow('지원 내용을 작성해주세요.', 'warn');
				} else if(supportRows.supportNames.indexOf(this.loginUserName) == "-1" && this.isAdmin != 'Y') {						
					this.$messageShow('권한이 없습니다.(작업자만 가능)', 'warn');
				} else {
					return true;
				}				
			} else if(supportRows == undefined) {	//지원추가, 지원추가(마지막 값 복붙)
				if(this.supportRows.length != 0) {
					var last_supportRows = this.supportRows[this.supportRows.length-1];	
					var scd_Label = last_supportRows.supportCategoryDetail.label;										
					if(last_supportRows.supportCategoryDetail == "") {
						this.$messageShow('지원구분을 선택해주세요.', 'warn');
					} else if(last_supportRows.supportName == "") {
						this.$messageShow('작업자를 선택해주세요.', 'warn');
					} else if(last_supportRows.supportProd == "") {
						this.$messageShow('제품을 선택해주세요.', 'warn');
					} else if(last_supportRows.serverType == "" && (scd_Label != "교육" && scd_Label != "미팅")) {
						this.$messageShow('서버구분을 선택해주세요.', 'warn');
					} else if(last_supportRows.supportDetailsInstallDoc == "" && scd_Label == "설치") { 
						this.$messageShow('설치문서 URL을 입력해주세요.', 'warn');
					} else if (this.checkURL(last_supportRows.supportDetailsInstallDoc) == false && scd_Label == "설치") {
						this.$messageShow('설치문서 URL을 확인해주세요.', 'warn');
					} else if(last_supportRows.supportDetail == "" && last_supportRows.supportStatus.label != "작업전") {
						this.$messageShow('지원내용을 입력해주세요.', 'warn');
					} else if(last_supportRows.supportStatus == "") { 
						this.$messageShow('지원상태를 선택해주세요.', 'warn');
					} else {						
						const result = last_supportRows.supportName.find(user => user.name === this.loginUserName);
						if(result == undefined && this.isAdmin != 'Y') {						
							this.$messageShow('권한이 없습니다.(작업자만 가능)', 'warn');
						} else {
							return true;
						}						
					}
				} else {
					return true;
				}
			} else { //저장 버튼 클릭 시 체크
				var current_supportRows = supportRows;				
				var current_scd_Label = '';					
				if(current_supportRows.supportCategoryDetail != undefined) {
					current_scd_Label = current_supportRows.supportCategoryDetail;										
				} else {					
					current_scd_Label = current_supportRows;					
				}				
				if(current_supportRows.supportCategoryDetail == "" || current_supportRows.supportCategoryDetail == undefined) {
					this.$messageShow('('+itemIndex+')지원구분을 선택해주세요.', 'warn');
				} else if(current_supportRows.supportNames == "") {
					this.$messageShow('('+itemIndex+')작업자를 선택해주세요.', 'warn');
				} else if(current_supportRows.supportProd == "" || current_supportRows.supportProd == undefined) {
					this.$messageShow('('+itemIndex+')제품을 선택해주세요.', 'warn');
				} else if((current_supportRows.serverType == ""  || current_supportRows.serverType == undefined) && (current_scd_Label != "교육" && current_scd_Label != "미팅")) {
					this.$messageShow('('+itemIndex+')서버구분을 선택해주세요.', 'warn');				
				} else if(current_supportRows.supportDetailsInstallDOC == "" && current_scd_Label == "설치") { 
					this.$messageShow('('+itemIndex+')설치문서 URL을 입력해주세요.', 'warn');
				} else if (this.checkURL(current_supportRows.supportDetailsInstallDOC) == false && current_scd_Label == "설치") {
					this.$messageShow('('+itemIndex+')설치문서 URL을 확인해주세요.', 'warn');
				}  else if(current_supportRows.supportDetail == "" && current_supportRows.supportStatus != "작업전") {
					this.$messageShow('('+itemIndex+')지원내용을 입력해주세요.', 'warn');
				} else if(current_supportRows.supportStatus == "" || current_supportRows.supportStatus == undefined ) { 
					this.$messageShow('('+itemIndex+')지원상태를 선택해주세요.', 'warn');
				} else {					
					if(current_supportRows.supportNames.indexOf(this.loginUserName) == "-1" && this.isAdmin != 'Y') {						
						this.$messageShow('('+itemIndex+')권한이 없습니다.(작업자만 가능)', 'warn');
					} else {
						return true;
					}					
				}
			}
			
		},
		onResCloseDialog(row){							
			if(!this.responseSaved) {			
				row.supportDetail = this.responseDetail2;				
			}
		},
		onSaveResponse(row){
			row.supportDetailVisible = false;
			this.responseSaved = true;
		},		
		onVerHisSaveResponse(){
			this.versionHistoryVisible = false;
			this.versionHistorySaved = true;
		},

		setFileUpload(row) {            
            var frm = new FormData();
            var file_doc = document.getElementById("uploadFile_"+row.id);       
			var file = file_doc.files[0];			
			if(file!=null && file!=undefined){				
				if (file.size > 10485760) {
					this.$messageShow("업로드 용량 제한 : 10mb", 'error');
				} else {
					frm.append("uploadFile", file);							
					frm.append('uuidFilename', '');	
					frm.append('uploadType','installDOC');
					row.supportDetailsInstallDoc = frm;
				}
			}			
		},
		clickFileInput(id) {
			const input = document.getElementById('uploadFile_'+id);
			if (input) {				
				input.click();
			}
		},
		setVersionHistory(row) {
			var curRows = this.supportRows.length-1;	
			this.supportRows[curRows].visitDate = row.visitDate;
			this.supportRows[curRows].serverType = row.serverType;
			this.supportRows[curRows].versionArr[0] = row.serverEngineVer;
			this.supportRows[curRows].versionArr[1] = row.viewerVer;
			this.supportRows[curRows].versionArr[2] = row.designerVer;
			this.supportRows[curRows].versionArr[3] = row.moduleVer;

			this.onVerHisSaveResponse();			
		},
		checkVersionHistory() {
			var curRows = this.supportRows.length-1;				
			if(this.versionHistory.length == 0) {
				this.$messageShow('과거 버전 기록이 없습니다.', 'warn');
			} else if(this.supportRows[curRows].supportCategoryDetail.label == "교육" || this.supportRows[curRows].supportCategoryDetail.label == "미팅") {		
				this.$messageShow('교육, 미팅 시에는 사용 할 수 없습니다.', 'warn');
			} else {
				this.versionHistoryVisible = true;
			}			
		},
		inputDate(){			
			if(this.visitDate > this.visitEndDate) {
				this.visitEndDate = this.visitDate;
			}
		},
		requestDetailsCopy(event) {
			if(this.supportCategory.code == 'ONSITE_SUPPORT' || this.supportCategory.code == 'OFFSITE_SUPPORT'){
				if (event.ctrlKey && event.key === 'c') {
					// placeholder 텍스트를 클립보드로 복사
					const tempTextarea = document.createElement("textarea");  // textarea 사용
					tempTextarea.value = this.phRequestDetails;
					document.body.appendChild(tempTextarea);
					tempTextarea.select();
					document.execCommand("copy");
					document.body.removeChild(tempTextarea);
					alert("Placeholder 텍스트가 복사되었습니다!");
				}
			}
		},
		checkURL(str){			
			const regex = /^(http|https):\/\/[\w-]+(\.[\w-]+)+[/#?]?.*$/;
    		return regex.test(str);
		},
		setDialogSize(){			
			if(this.isMobile) {
				this.dialogStyle = { 
					width: '100vw', 
					height: '100vh', 
					top: '50', 
					left: '0', 
					margin: '0', 
					position: 'fixed', 
					zIndex: 9999 
				};
				this.dialogContent = { minHeight: 'calc(60vh - 50px)', padding: '0' };
			} else {
				this.dialogStyle = { width: this.modalWidth, top: '50px', left: 'auto' };
				this.dialogContent = {minHeight: '35vw'};
			}
		}
	  }
	}
	</script>
	
	<style lang="css" scoped>
	.container {
	  display: flex;
	  margin-top: 20px;
	  margin-bottom: 10px;
	  margin-left: 0px;
	  justify-content: center
	}
	.container > * {
	  margin-right: 10px;
	  font-size: 15px;
	}
	.container > *:last-child {
	  margin-right: 0;
	}
	.container .selects {
	  display: flex;
	}
	.container .selects select {
	  width: 120px;
	  margin-right: 10px;
	}
	.container .selects select:last-child {
	  margin-right: 0;
	}
	
	.center {
	  text-align: center;
	  min-width: 90px;
	}
	.container .btn {
	  width: 120px;
	  height: 50px;
	  font-weight: 700;
	  flex-shrink: 0;
	}
	@media only screen and (max-width: 480px) {
		.container {
		  display: block;
		}
		.selects {
		  margin-top: 10px;
		}
		.btn {
		  margin-top: 10px;
		}
	}
	
	th{
	  text-align: center;
	}
	
	.pi{
	  min-width: 56px;
	  width: 56px;
	  min-height: 50px;
	  height: 50px;
	}	
	.mobileCss_tr {
		height: 4em;
	}
	.mobileCss_td {
		min-width: 8em;
		padding-right: 1em;
		vertical-align: middle;
	}
	.header-container {
		display: flex;
		justify-content: space-between; /* 요소를 양쪽 끝으로 배치 */
		align-items: center; /* 수직 가운데 정렬 */
		width:100%;
	}

	.header-container Button {
		margin-left: auto; /* 버튼을 오른쪽 끝으로 이동 */
	}
	</style>
	
	