<template>
<Dialog v-model:visible="visible" maximizable modal header="프로젝트 정보 수정" :style="{ width: modalWidth }" @update:visible="$emit('closesModal'); visible = false;">
	<div class="d-flex">							
			<Button label="히스토리" @click="visible2 = true, getProjectHistory()" />			
	</div>		
	<Dialog v-model:visible="visible2" modal header="히스토리" :style="{ width: modalWidth }" :breakpoints="{ '1199px': '50vw', '575px': '90vw' }">		
		<Timeline :value="projectHistoryList">
			<template #opposite="left" >						
				<small class="p-text-secondary">{{ left.item.regDate.substring(0,11) }}{{ left.item.regDate.substring(11,16) }}<br>{{ left.item.regUserName }}</small>				
			</template>			
			<template #content="right">
				<template v-for="(field, index) in Object.keys(getNextProjectName(historyHeader, right.item)).map((key) => key)" :key="index">
					<tr v-if="index >= 2 && getNextProjectName(historyHeader, right.item)[field].split('|*|')[1] !== ''">
						<td nowrap><small class="p-text-secondary"><B>{{ getNextProjectName(historyHeader, right.item)[field].split('|*|')[0] }}</B></small></td>
						<td nowrap style="max-width: 100px;"><small class="p-text-secondary" v-html="addNewLine(getNextProjectName(historyHeader, right.item)[field].split('|*|')[1])"></small></td>
					</tr>
				</template>
			</template>
		</Timeline>
	</Dialog>

	<div class="flex flex-wrap justify-content-center gap-2 mb-2">
	
		<Divider align="left" type="solid">
	    	<b>고객사</b>
		</Divider>
		<p>
		    <AutoComplete v-model="company" optionLabel="companyName" dropdown :suggestions="filteredCompanys" @complete="companySearch" @change="setProjectList" />
		</p>
		<Divider align="left" type="solid">
	    	<b>프로젝트명</b>
		</Divider>
			<div class="d-flex">
				<input type="text" class="form-control" v-model="projectName" maxlength="50" />
				<Button type="button" icon="pi pi-list" label="조회" @click="viewProjectsToggle" size="small" style="width: 100px;"/>
			</div>
			<OverlayPanel ref="op">
			     <Listbox v-model="projectList" disabled :options="projects" optionLabel="label" class="w-full md:w-14rem" />
			</OverlayPanel>
		<!-- <Divider align="left" type="solid">
	    	<b>도입년도</b>
		</Divider>
		<p>
		    <InputText maxlength="4" v-model="year" style="width: 100px"/>
		</p>	 -->
		<Divider align="left" type="solid">
	    	<b>납품제품</b>
		</Divider>
		<p>
			<!--
		    <AutoComplete v-model="deliveryProd" optionLabel="label" multiple dropdown :suggestions="filteredDeliveryProds" @complete="deliveryProdSearch" />
			-->
			<MultiSelect v-model="deliveryProd" display="chip" :options="deliveryProds"  optionLabel="label" placeholder="제품 선택" filter :maxSelectedLabels="5" class="w-full md:w-20rem" />
		</p>
		<Divider align="left" type="solid">
	    	<b>번들사 여부</b>
		</Divider>
		<p>
		    <input type="radio" v-model="partnerYn" value="Y" id="partnerYnY"/>
	        <label for="partnerYnY">있음</label>
	        <input type="radio" v-model="partnerYn" value="N" id="partnerYnN" @click="this.partnerName = ''"/>
	        <label for="partnerYnN">없음</label>
		</p>
		<Divider align="left" type="solid" v-if="this.partnerYn==='Y'">
	    	<b>번들사</b>
		</Divider>
		<p v-if="this.partnerYn==='Y'">
		    <input type="text" class="form-control" v-model="partnerName" maxlength="50" />
		</p>
		<Divider align="left" type="solid">
	    	<b>영업대표</b>
		</Divider>
		<p>
		    <AutoComplete v-model="salesRepresentative" optionLabel="name" dropdown :suggestions="filteredSalesRepresentativeEmails" @complete="salesRepresentativeSearch" />
		</p>
		<Divider align="left" type="solid">
	    	<b>비고</b>
		</Divider>
		<p>
		    <Textarea v-model="etc" variant="filled" rows="2" cols="70" maxlength="500" style="width: 100%" />
		</p>		
	   </div>
      <div class="flex flex-wrap justify-content-center gap-2 mb-2">
      	<b style="color:red;font-size:14px;">{{errorMessage}}</b>
      </div>
      <template #footer>
        <Button label="취소" icon="pi pi-times" @click="$emit('closesModal'); visible = false;" text />
        <Button label="저장" icon="pi pi-check" @click="registProjectinfo" autofocus />
		<div class="flex align-items-center gap-2" style="margin: 15px 10px 0px 0px;">               
			<span class="p-text-secondary"><b>{{ modifierName == "" ? registerName : modifierName }}</b></span><br>          
			<span class="p-text-secondary"><div class="text-xs flex align-items-center text-700"><i class="pi pi-calendar mr-1 text-xs"></i> {{ modDate == null ? regDate : modDate }}</div></span>
		</div>     
    </template>
  </Dialog>
</template>

<script>
import _ from "lodash";
export default {
  name: 'ProjectInfoRegistModal',
  data(){
    return{
      companys: [],
      projects: [],
      filteredCompanys: [],
      deliveryProds: [],
      filteredDeliveryProds: [],
      salesRepresentativeEmails: [],
      filteredSalesRepresentativeEmails: [],
      company: "",
      deliveryProd: "",
      salesRepresentative: "",
      projectName: "",
      year: "",
      partnerYn: "Y",
      partnerName: "",
      errorMessage : "",
      visible: true,
	  visible2: false,
      modalWidth: this.$isMobile() ? '100vw' : '35vw',
	  modifierName: "",
      modDate: "",
	  registerName: "",
      regDate: "",
	  projectHistoryList: [],
	  historyHeader: ["수정일","수정자","고객사", "프로젝트", "도입년도", "번들여부", "번들사", "영업대표","납품제품","일련번호","비고"],
	  details: {},
	  etc: "",
    }
  },
  props: {
  	detailsCompanySeq: Number,
  	detailsProjectUid: String,
  },
  components: {
  },
  created(){
	var param = {
    };
	this.$code(["PROD_CODE"], false,
		function(result, i){
           i.deliveryProds = result.prodCode;
        },
        function(e, i){
        	console.log(e);
        }
	);

  },
  mounted(){
  		this.$nextTick(function() {
  			this.setProjectInfo();
        });
  },
  methods: {
    registProjectinfo() {
    	this.errorMessage = '';
    	var checked = false;
		var checked2 = false;
    	var deliveryProdCodes = [];
    	
		var deliveryProd = "";
		for(var i = 0; i< this.deliveryProd.length; i++) {
			deliveryProd += i == this.deliveryProd.length-1 ? this.deliveryProd[i].codeName : this.deliveryProd[i].codeName +",";
		}

		const isIncluded = this.projectName && this.projects.some(project => project.projectName.includes(this.projectName));
		
      	if(confirm("프로젝트 정보를 변경하시겠습니까?")){
			if(isIncluded){
				if(confirm("미리 생성된 유사한 프로젝트가 있는데 정말 진행 하시겠습니까???")){
					checked2 = true;
				}
			} else {
				checked2 = true;
			}
			if(checked2) {
				if (this.details.companyName == this.company.companyName && this.details.projectName == this.projectName && this.details.year == this.year
				&& this.sortAndSplit(this.details.deliveryProdName,',') == this.sortAndSplit(deliveryProd,',') 
				&& this.details.partnerYn == this.partnerYn && this.details.partnerName == this.partnerName && this.details.salesRepresentativeName == this.salesRepresentative.name
				&& this.details.etc == this.etc) {
						checked = false;
						this.$messageShow('기존 정보와 같습니다.', 'warn');
						return checked;
				} else if(this.partnerYn == 'Y'){
					if(this.company == "") {
						this.$messageShow('고객사명은 필수입력항목입니다.', 'warn');
					}else if(this.projectName == "") {
						this.$messageShow('프로젝트명은 필수입력항목입니다.', 'warn');
					}/*else if(this.year == "") {
						this.$messageShow('도입년도는 필수입력항목입니다.', 'warn');
					}*/else if(this.partnerName == "") {
						this.$messageShow('번들사명은 필수입력항목입니다.', 'warn');
						return checked;
					}else if(this.salesRepresentative == "") {
						this.$messageShow('영업대표명은 필수입력항목입니다.', 'warn');
						return checked;
					}else{
						checked = true;
					}
				}else{
					if(this.company == "") {
						this.$messageShow('고객사명은 필수입력항목입니다.', 'warn');
					}else if(this.projectName == "") {
						this.$messageShow('프로젝트명은 필수입력항목입니다.', 'warn');
					}else if(this.year == "") {
						this.$messageShow('도입년도는 필수입력항목입니다.', 'warn');
					}else if(this.salesRepresentative == "") {
						this.$messageShow('영업대표명은 필수입력항목입니다.', 'warn');
						return checked;
					}else{
						checked = true;
					}
				}


				
				if(this.company.companyName == undefined || this.company.companyName == 'undefined'){
					this.$messageShow('고객사명이 잘못 선택되었습니다.', 'warn');
					checked = false;
				}
				
				var regExp = /^(20[0-9]{2})$/i;
				if(!regExp.test(this.year)){
					this.$messageShow("도입년도가 정확하지 않습니다.", 'warn');
					return checked;
				}
				
				if(this.salesRepresentative.userEmail == undefined || this.salesRepresentative.userEmail == 'undefined'){
					this.$messageShow('영업대표명이 잘못 선택되었습니다', 'warn');
					checked = false;
				}
				
				if(this.deliveryProd != null && this.deliveryProd != 'undefined' && this.deliveryProd != ''){

					this.deliveryProd = _.uniqBy(this.deliveryProd, 'value');
				
					this.deliveryProd.forEach(function(prod, index) {
					deliveryProdCodes.push(prod.value);
					});
					
					if(deliveryProdCodes.length < 1){
						this.$messageShow('납품제품은 한개이상 필수선택항목입니다.', 'warn');
						checked = false;
						return checked;
					}
					
				}else{
					this.$messageShow('납품제품은 한개이상 필수선택항목입니다.', 'warn');
					checked = false;
					return checked;
				}
				
				if(checked){
					var param = {
					"companySeq": this.company.companySeq ,
					"projectUid": this.detailsProjectUid ,
					"projectName": this.projectName,
					"year": this.year,
					"deliveryProdCodes" : deliveryProdCodes,
					"partnerYn" : this.partnerYn,
					"partnerName" : this.partnerName,
					"salesRepresentativeEmail" : this.salesRepresentative.userEmail,
					"modifierEmail" : this.$store.getters.getUserEmail,
					"etc" : this.etc,
					};
					
					this.$post('/biz/project/mod', param,
								function(response, i){
									i.$messageShow('저장이 완료되었습니다.', 'success');
									i.visible = false;
									i.$emit('closesModal');
									i.$emit('update');
								},
								function(e, i){
									i.$messageShow(e, 'danger');
								}
					);      	
				} 
			}  
      	}
    },
    deliveryProdSearch(event) {
             setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredDeliveryProds = [...this.deliveryProds];
                } else {
                    this.filteredDeliveryProds = this.deliveryProds.filter((deliveryProd) => {
                        return deliveryProd.label.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
            
            
    },
    companySearch(event) {
             setTimeout(() => {
                if (!event.query.trim().length) {
					this.filteredCompanys = this.companys.filter((company) => company.useYn === 'Y');
                } else {
                    this.filteredCompanys = this.companys.filter((company) => {
						return company.useYn === 'Y' && company.companyName.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
            
            
    },
    salesRepresentativeSearch(event) {
             setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredSalesRepresentativeEmails = [...this.salesRepresentativeEmails];
                } else {
                    this.filteredSalesRepresentativeEmails = this.salesRepresentativeEmails.filter((salesRepresentative) => {
                        return salesRepresentative.name.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
            
    },
    setProjectInfo(){
    	
    	const current = new Date();
    
    	var param = {
	    	companySeq : this.detailsCompanySeq,
	    	projectUid : this.detailsProjectUid
	    };
	  
	  	this.$get(`/biz/project/get`, {
		            	params: param
		       		 },
		                function(response, i){
		                	
		                	var detail = response.data.detail;							
							i.details = detail;
		                	i.modifierName = detail.modifierName;
							i.modDate = detail.modDate;
							i.registerName = detail.registerName;
							i.regDate = detail.regDate;			
					    	i.$get('/biz/company/list',  {
							            	params: param
							       		 },
						                function(response, i){
						                   	i.companys = response.data.result;   //기존데이터에 추가
						                   	
						                   	response.data.result.forEach(function(company, index) {
							                	if(detail.companySeq === company.companySeq){
							                		i.company = company;
							                		var param = {
														companySeq : i.company.companySeq,
													};
											    
													i.$get('/biz/project/list',  {
													            	params: param
														 },
													        function(response, i){
																i.projects = response.data.result.filter((project) => project.useYn === 'Y');   //기존데이터에 추가
													        },
													        function(e, i){
													        	console.log(e);
													        }
													);
							                	}
											});
						                },
						                function(e, i){
						                	console.log(e);
						                }
						     );
		                	
		                	i.projectName = response.data.detail.projectName;
		                	i.year = response.data.detail.year !== "" ? response.data.detail.year : current.getFullYear();
		                	
		                	var deliveryProds = [];
		                	
		                	i.$code(["PROD_CODE"], false,
								function(result, i){
						           //i.deliveryProds = result.prodCode.filter(item => item.codeSort < 10 || item.codeSort >= 1000);
								   i.deliveryProds = result.prodCode.filter(item => item.codeSort < 20 || item.codeSort >= 1000);
						           
						           result.prodCode.forEach(function(deliveryProd, index) {
					                	detail.deliveryProdCode.split(",").forEach(function(prodCode, index) {
						                	if(prodCode === deliveryProd.value){
						                		deliveryProds.push(deliveryProd);
						                	}
					                	});
					                	
									});
									
									i.deliveryProd = deliveryProds;
						        },
						        function(e, i){
						        	console.log(e);
						        }
							);
		                	
		                	i.partnerYn = response.data.detail.partnerYn;
		                	i.partnerName = response.data.detail.partnerName;
		                	i.etc = response.data.detail.etc;
		                	i.$get('/biz/project/sr-list',  {
							            	params: param
							       		 },
						                function(response, i){
						                	i.salesRepresentativeEmails = response.data.result;   //기존데이터에 추가
						                	response.data.result.forEach(function(salesRepresentative, index) {
							                	if(detail.salesRepresentativeEmail === salesRepresentative.userEmail){
							                		i.salesRepresentative = salesRepresentative;
							                	}
											});
						                },
						                function(e, i){
						                	console.log(e);
						                }
						     );
		                	
		                	
		                },
		                function(e, i){
		                	console.log(e);
		                }
		 );
    
    }, 
    setProjectList() {
    	if(this.company.companySeq != undefined){
			var param = {
				companySeq : this.company.companySeq,
			};
	    
			this.$get('/biz/project/list',  {
			            	params: param
				 },
			        function(response, i){
			    		//i.projects = response.data.result;   //기존데이터에 추가
						i.projects = response.data.result.filter((project) => project.useYn === 'Y');   //기존데이터에 추가						
			        },
			        function(e, i){
			        	console.log(e);
			        }
			);
		}
            
    },
    viewProjectsToggle(event) {
		this.$refs.op.toggle(event);
    },
	getProjectHistory() {    
		var param = {
			projectUid : this.detailsProjectUid
		};	
		this.$get('/biz/project/getH',  {
						params: param
				},
				function(response, i){
					i.projectHistoryList = response.data.detailHistory;
				},
				function(e, i){
					console.log(e);
				}
		);	
            
    },
	getNextProjectName(header, item) {      		
		const currentIndex = this.projectHistoryList.indexOf(item);		
			
		var properties = Object.keys(item).map((key) => key);
		var rtValue = {};
		
		if (currentIndex < this.projectHistoryList.length - 1) {		
			for (let i = 0; i < header.length; i++) {
				let property = properties[i];			
				rtValue[property] = (this.projectHistoryList[currentIndex][property] == this.projectHistoryList[currentIndex + 1][property] ? "" : header[i] + '|*|' + (this.projectHistoryList[currentIndex][property] != null ? this.projectHistoryList[currentIndex][property] : ""));
			}
		} else {			
			for (let i = 0; i < header.length; i++) {
			let property = properties[i];			
			rtValue[property] = header[i] + '|*|' + (this.projectHistoryList[currentIndex][property] != null ? this.projectHistoryList[currentIndex][property] : "");
			}
		}
		return rtValue;
	},
	addNewLine(value) {						
		if(value != undefined && value != null) {				
			if(value.indexOf('.') == -1 && value.indexOf('allip') == -1)
				return value;
			else
				return value.replace(/,/g, '<br>');
				
		}						
	},
	sortAndSplit(str, splitChar) {
		return str.split(splitChar).map(item => item.trim()).sort().join(splitChar);
	},
  }
}
</script>

<style scoped>
.black-bg {
  width: 100%; 
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  padding: 20px;
}
.white-bg {
  width: 400px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  transform: translate(-50%, 5%);
}
h4 {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(169, 168, 168);
}
.check {
  margin-left: 40px;
}

.p-timeline-event-opposite{
	flex: -1;
}

.p-timeline-event-content{
	flex: -1;
}

</style>

