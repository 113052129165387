<template>
	<List :isMobile="isMobile"/>
</template>
<script>
import List from './SupportOld2024List.vue'
import axios from 'axios'
var requestOptions = {
  headers: {
    'Content-Type': 'application/json',
    timeout: 60000
  }
};
export default {
  name: 'SupportOld2024Main',
  data() {
    return {      
      engineerRoleCheck: false, 
      isMobile : this.$isMobile(),
    };
  },
  props: {
    menuSeq: {
      type: Number,
      default: 0,
    },
  },
  components: {
    List,
  },
  created() {  	
  },
  mounted(){
    this.onResize();
    window.addEventListener('resize',this.onResize,{passive:true});
  },
  beforeDestory(){
    if(typeof window === 'undefined') return;
    window.removeEventListener('resize',this.onResize,{passive:true});
  },
  methods: {
  	onResize(){
      this.isMobile = window.innerWidth < 600
    },
  },
};
</script>

<style lang="css" scoped>

</style>