<template>
  <transition name="fade" @closesModal="projectInfoUpdateModal = false" @update="update = true">
    <projectInfoUpdate :detailsCompanySeq="detailsCompanySeq" :detailsProjectUid="detailsProjectUid" :update="update" v-if="projectInfoUpdateModal" />
  </transition>

  <transition name="fade" @closesModal="projectSerialNoUpdateModal = false" @update="update = true">
    <projectSerialNoUpdate :detailsProject="detailsProject" :engineerRoleCheck="engineerRoleCheck" :update="update" v-if="projectSerialNoUpdateModal" />
  </transition>

  <transition name="fade" @closesModal="projectInfoRegistModal = false" @update="update = true">
    <projectInfoRegist v-if="projectInfoRegistModal" />
  </transition>

  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">프로젝트 관리</h1>
  </div>

  <!-- 검색 조건 -->
  <div class="container" v-if="isMobile == false">
    <SelectButton v-model="selectedOption" :options="options" optionDisabled="constant" optionLabel="name" aria-labelledby="basic" @click="changePage(1, this.pagination.pageSize)" 
    style="display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap;" />    
    <input v-model="searchKeyword" class="form-control" type="text" placeholder="프로젝트 / 고객사 / 번들사"  @keyup.enter="changePage(1, this.pagination.pageSize)" />
    <input v-model="searchKeywordProjectSerialNo" class="form-control" type="text" placeholder="일련번호"  @keyup.enter="changePage(1, this.pagination.pageSize)" style="width: 200px;"/>
    <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText"></Button>		
    <Button class="pi pi-pencil" severity="warning" @mouseover="setHoverText('등록')" @mouseout="clearHoverText" :title="hoverText" @click="projectInfoRegistModal = true"></Button>
  </div>
  <div class="container" v-if="isMobile == true">
    <div class="d-flex">
    <input v-model="searchKeyword" class="form-control" type="text" placeholder="프로젝트 / 고객사 / 번들사"  @keyup.enter="changePage(1, this.pagination.pageSize)" />  
      <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText"></Button>		
      <Button class="pi pi-pencil" severity="warning" @mouseover="setHoverText('등록')" @mouseout="clearHoverText" :title="hoverText" @click="projectInfoRegistModal = true"></Button>
    </div>
  </div>


  <!-- 본문 -->
  <div class="container" v-if="isMobile == false">	    
	  <table class="table table-bordered table-hover caption-top">
	    <thead class="table-light">
	      <tr class="table-primary">
	        <th scope="col" v-for="item in header" :key="item" :style="{ width: headerWidths[item]}" class="center">
	          {{ item }}
          </th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr v-for="(row, i) in project" :key="i"  >
          <td class="left" style="cursor: pointer;" @click="projectInfoUpdateModal = true; detailsProjectUid = row.projectUid; detailsCompanySeq = row.companySeq;" >{{ row.companyName }}</td>
          <td style="cursor: pointer;" @click="projectInfoUpdateModal = true; detailsProjectUid = row.projectUid; detailsCompanySeq = row.companySeq;" >{{ row.projectName }}</td>
          <!-- <td class="center" style="cursor: pointer;" @click="projectInfoUpdateModal = true; detailsProjectUid = row.projectUid; detailsCompanySeq = row.companySeq;" >{{ row.year }}</td> -->
          <td style="cursor: pointer;" @click="projectInfoUpdateModal = true; detailsProjectUid = row.projectUid; detailsCompanySeq = row.companySeq;">{{ row.deliveryProdName }}</td>
          <td class="left" style="cursor: pointer;" @click="projectInfoUpdateModal = true; detailsProjectUid = row.projectUid; detailsCompanySeq = row.companySeq;">{{ row.partnerName }}</td>
          <td class="center" style="cursor: pointer;" @click="projectInfoUpdateModal = true; detailsProjectUid = row.projectUid; detailsCompanySeq = row.companySeq;">{{ row.salesRepresentativeName }}</td>
          <td class="center">{{ row.projectSerialNo }}</td>
          <td class="center"><button class="btn btn-primary" @click="popupProjectSerialNoUpdateModal(row)" style="width: 86px;font-size: x-small;height: 25px;">일련번호</button></td>
          <td class="center" v-if="isAdmin == 'Y'" style="cursor: pointer; text-overflow:ellipsis;"><Checkbox v-model="row.useYn" trueValue="Y" @change="onCheckFocus(row)" invalid binary /></td>   
        </tr>
      </tbody>
    </table>
  </div>
 
  <!-- 본문 모바일 -->
  <div class="container" v-if="isMobile == true">
      <Accordion :activeIndex="0">
        <AccordionTab v-for="(row, i) in project" :key="i" :header="row.companyName + ' - ' + row.projectName">
            <p>고객사 : {{ row.companyName }}</p>
            <p>프로젝트 : {{ row.projectName }}</p>
            <p>사업년도 : {{ row.year }}</p>
            <p>납품제품 : {{ row.deliveryProdName }}</p>
            <p>번들사 : {{ row.partnerName }}</p>
            <p>영업대표 : {{ row.salesRepresentativeName }}</p>            
            <button
            class="btn btn-secondary"
            @click="projectInfoUpdateModal = true; detailsProjectUid = row.projectUid; detailsCompanySeq = row.companySeq;">
            수정
          </button>
        </AccordionTab>
      </Accordion>
    </div>
  
  <div class="container">
	  <Pagination :pagination="pagination" @page-change="changePage" v-if="isMobile == false"></Pagination>
	  <mPagination :pagination="pagination" @page-change="changePage" v-if="isMobile == true"></mPagination>
  </div>
</template>

<script>
import projectInfoUpdate from './Update.vue'
import projectInfoRegist from './Regist.vue'
import projectSerialNoUpdate from './ProjectSerialNoUpdate.vue'
import Pagination from '@/components/Common/ClipPagination.vue'
import mPagination from '@/components/Common/mClipPagination.vue'
import axios from 'axios'
export default {
  name: 'ProjectMain',

  data() {
    return {
      projectInfoUpdateModal: false,
      projectInfoRegistModal: false,
      projectSerialNoUpdateModal: false,
      project: {},
      header: ["고객사", "프로젝트", /*"도입년도", */"납품제품", "번들사", "영업대표","일련번호","버튼"],
      headerWidths: {'고객사':'18%', '프로젝트':'18%', '도입년도':'6%','납품제품':'15%','번들사':'10%','영업대표':'6%','일련번호':'12%','버튼':'5%','사용여부':'10%'}, // 각 열의 넓이
      detailsProjectSeq: "",
      update: false,
      searchKeyword: '',
      searchKeywordProjectSerialNo: '',
      currentPage: 1,        // 현재 페이지
      itemsPerPage: 10,      // 페이지당 항목 수
      total: 0,      // 페이지당 항목 수
      detailsProjectUid : "",
      detailsCompanySeq : "",
      detailsProjectSerialNo : "",
      detailsProject: {},
      isMobile : this.$isMobile(),
      engineerRoleCheck: false, 
      pagination: {
    		endRow: 0,
		    hasNextPage: false,
		    hasPreviousPage: false,
		    isFirstPage: true,
		    isLastPage: true,
		    navigateFirstPage: 0,
		    navigateLastPage: 0,
		    navigatePages: 10,
		    navigatepageNums: [],
		    nextPage: 0,
		    pageNum: 1,
		    pageSize: 10,
		    pages: 0,
		    prePage: 0,
		    size: 0,
		    startRow: 0,
		    total: 0
    	},
      hoverText: '',      
      isAdmin: 'N',
      selectedOption: { name: '이름순', value: '이름순', constant: false }, 
      options: [
                { name: '이름순', value: '이름순', constant: false },
                { name: '수정일순', value: '수정일순', constant: false }
            ],
    };
  },
  props: {
    menuSeq: {
      type: Number,
      default: 3,
    },
  },
  components: {
    projectInfoUpdate,
    projectInfoRegist,
    projectSerialNoUpdate,
    Pagination,
    mPagination,
  },
  created() {
    var paramData = {
    	"pageNum": 1,
		  "pageSize": this.isMobile ? 5 : 10,
    };
   
    var requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        timeout: 60000
      }
    };
    var self =  this;
    //팀 or 관리자 권한 체크
    this.$post(`/biz/account/${this.$store.getters.getUserEmail}/team/get`, paramData,
              function(response, i){             
                i.isAdmin = response.data.adminYn;                
                if ( response.data.teamCode == 'MMS') {                                 
                  i.engineerRoleCheck = true;   
                  i.searchKeywordProjectSerialNo = '0000';//검색 입력값에 '0000' 셋팅                  
                } else if (response.data.adminYn == 'Y') { 
                  i.engineerRoleCheck = true;  
                } else {
                  i.engineerRoleCheck = false;
                }                
                i.changePage(1, self.isMobile ? 5 : 10)
              },
              function(e, i){
              	console.log(e);
              }
    );

 
  }, watch: {
    update(update) {
      if (update == true) {
        this.changePage(this.currentPage, this.pagination.pageSize);
      }
    }
  },
  computed: {
  },
  mounted(){
    this.onResize();
    window.addEventListener('resize',this.onResize,{passive:true});
  },
  beforeDestory(){
    if(typeof window === 'undefined') return;
    window.removeEventListener('resize',this.onResize,{passive:true});
  },
  methods: {
    // 페이지 변경 시 호출될 함수
    changePage(newPage, pageSize) {
      this.currentPage = newPage;
      var paramData = {
        "pageNum": newPage,
        "pageSize": pageSize,
        "searchKeyword": this.searchKeyword,
        "searchProjectSerialNo": this.searchKeywordProjectSerialNo,
        "isAdmin":this.isAdmin,        
        "sortOption":this.selectedOption.value,
	    };
	 
    this.$get('/biz/project/list', {
              params: paramData
            },
              function(response, i){
                i.project = response.data.pagination.list;   //기존데이터에 추가
                i.pagination = response.data.pagination;
                i.header = i.isAdmin == 'Y' ? i.header = ["고객사", "프로젝트", "납품제품", "번들사", "영업대표", "일련번호", "버튼", "사용여부"] : i.header = ["고객사", "프로젝트", "납품제품", "번들사", "영업대표","일련번호","버튼"]
              },
              function(e, i){
                console.log(e);
              }
    );
    

     
    },
    onResize(){
      this.isMobile = window.innerWidth < 600
    },
    setHoverText(text) {
      this.hoverText = text;
    },
    clearHoverText() {
      this.hoverText = '';
    },
    popupProjectSerialNoUpdateModal(row){    
      this.projectSerialNoUpdateModal = true; 
      this.detailsProject = row;
    },
    onCheckFocus(row){      
      var self = this;      
      if(this.isAdmin == 'Y'){      
        var param = {	          
            "companySeq" : row.companySeq,
            "projectUid" : row.projectUid,
            "modifierEmail" : this.$store.getters.getUserEmail,            
            "useYn" : row.useYn == false ? 'N' : 'Y'
          };
        this.$post(`/biz/project/mod/use-yn`, param,
                function(response, i){
                  self.$messageShow('수정되었습니다.', 'success');                 
                },
                function(e, i){
                  console.log(e);
                }
        );
      } else {
        this.$messageShow('권한이 없습니다.(관리자만 가능)', 'warn');
        if(row.useYn == false) {
          row.useYn = 'Y';
        } else {
          row.useYn = '';
        }
      }    
    }, 
  }
};
</script>

<style lang="css" scoped>
.container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  justify-content: center
}
.container > * {
  margin-right: 10px;
  font-size: 15px;
}
.container > *:last-child {
  margin-right: 0;
}
.container .selects {
  display: flex;
}
.container .selects select {
  width: 120px;
  margin-right: 10px;
}
.container .selects select:last-child {
  margin-right: 0;
}

.center {
  text-align: center;
}
.container .btn {
  width: 120px;
  height: 50px;
  font-weight: 700;
  flex-shrink: 0;
}
@media only screen and (max-width: 480px) {
	.container {
	  display: block;
	}
	.selects {
	  margin-top: 10px;
	}
	.btn {
	  margin-top: 10px;
	}
}

th{
  text-align: center;
}

.pi{
  min-width: 56px;
  width: 56px;
  min-height: 50px;
  height: 50px;
}
</style>