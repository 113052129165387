<template>
<Dialog v-model:visible="visible" maximizable modal header="고객사 정보 등록" :style="{ width: modalWidth }" @update:visible="$emit('closesModal'); visible = false;">
	  <div class="flex flex-wrap justify-content-left gap-2 mb-2">
      	<Divider align="left" type="solid">
	    	<b>고객사명</b>
		</Divider>
		<p/>
			<div class="d-flex">
		    <input type="text" class="form-control" v-model="companyName" v-on:mouseleave="callCheck" :disabled="companyNameDisabled" /><Button size="small" label="중복체크" @click="checkDuplication" :disabled="companyNameDisabled" style="width: 120px;" />			
			</div>
		
		<Divider align="left" type="solid">
	    	<b>사업구분</b>
		</Divider>
		<p>
	        <Dropdown v-model="businessType"  :options="codes"  optionLabel="label" placeholder="사업구분 선택" class="w-full md:w-14rem" />
		</p>
		<Divider align="left" type="solid">
	    	<b>홈페이지 주소</b>
		</Divider>
		<p>
		    <input type="text" class="form-control" v-model="homepageUrl" />
		</p>
		<p>
			ex). https://www.raumsoft.co.kr 
		</p>
		
      </div>
      <div class="flex flex-wrap justify-content-center gap-2 mb-2">
      	<b style="color:red;font-size:14px;">{{errorMessage}}</b>
      </div>
      <template #footer>
        <Button label="취소" icon="pi pi-times" @click="$emit('closesModal'); visible = false;" text />
        <Button label="등록" icon="pi pi-check" @click="registCompanyinfo" autofocus />
    </template>
  </Dialog>
</template>

<script>

export default {
  name: 'CompanyInfoRegistModal',
  data(){
    return{
      codes: {},
      companys: [],
      companyName: "",
      businessType: "",
      homepageUrl: "",
      errorMessage : "",
      visible: true,
      modalWidth: this.$isMobile() ? '100vw' : '35vw',
	  requiredCheck: false,
	  companyNameDisabled: false,
    }
  },
  props: {
    update: Boolean
  },
  components: {
  },
  created(){
    var param = {
    };
    
    this.$code(["BUSINESS_TYPE"], false,
		function(result, i){
           i.codes = result.businessType;
        },
        function(e, i){
        	console.log(e);
        }
	);
	
    this.$get('/biz/company/list',  {
	            	params: param
	       		 },
                function(response, i){
                   	i.companys = response.data.result;   //기존데이터에 추가
                },
                function(e, i){
                	console.log(e);
                }
     );
	
  },
  methods: {
    registCompanyinfo() {
    	var checked = false;
		const regex = /[^가-힣0-9]/g;
      	if(confirm("고객사 정보를 등록하시겠습니까?")){
	        if(this.companyName == "") {
	          this.$messageShow('고객사명은 필수입력항목입니다.', 'warn');
	          checked = false;
	          return checked;
			}else if(this.requiredCheck == false) {
	          this.$messageShow('중복체크는 필수입니다.', 'warn');
	          checked = false;
	          return checked;
			}else if (regex.test(this.companyName)) {
				this.$messageShow('특수문자, 영문, 공백은 입력할 수 없습니다.', 'warn');
				checked = false;
	        	return checked;
	        }else if(this.businessType == "") {
	          this.$messageShow('사업구분은 필수입력항목입니다.', 'warn');
	          checked = false;
	          return checked;
	        }else{
	        	var companyName = this.companyName;
	        	
	        	if(this.companys.length > 0){
		        	for(let i=0; i<this.companys.length; i++){
					  if(companyName != this.companys[i].companyName){
					  	checked = true;
					  }else{
					  	checked = false;
					  	this.$messageShow('이미 등록된 고객사 정보입니다.', 'warn');
						return checked;
					  }
					}
				}else{
					checked = true;
				}
	        }
		      if(checked){
		        var param = {
		          "companyName": this.companyName ,
		          "businessType": this.businessType.value,
		          "homepageUrl" : this.homepageUrl,
		          "registerEmail" : this.$store.getters.getUserEmail
		        };
		        
		        this.$post('/biz/company/add', param,
			                function(response, i){
			                	i.$messageShow('등록이 완료되었습니다.', 'success');
			                	i.visible = false;
			                	i.$emit('closesModal');
		          				i.$emit('update');
			                },
			                function(e, i){
			                	i.$messageShow(e, 'danger');
			                }
			 	);
	        
	    	}
	        
      }
    },
	callCheck() {
		if(this.companyName != "") {
			const regex = /[^가-힣0-9]/g;
			if (regex.test(this.companyName)) {
				this.errorMessage = "특수문자, 영문, 공백은 입력할 수 없습니다.";				
			} else {
				this.errorMessage = "";
			}
		}
  	},
	checkDuplication() {
		var companyName = this.companyName;
		var flag = false;		
		
		if(this.companys.length > 0 && companyName != ""){
			for(let i=0; i<this.companys.length; i++){
				if(companyName == this.companys[i].companyName){
					flag=true;
					break;
				} 
			}			
		}		
		this.callCheck();

		if(flag == true) {
			this.$messageShow('이미 등록된 고객사 정보입니다.', 'warn');
		} else if(companyName == "") {
			this.$messageShow('고객사명은 필수입력항목입니다.', 'warn');
		} else if(this.errorMessage != "") {
			this.$messageShow('특수문자, 영문, 공백은 입력할 수 없습니다.', 'warn');
		} else {
			var param = {
				companyName : companyName,
			};	
			this.$get('/biz/company/nameHistory',  {			
							params: param
						},
						function(response, i){
							if(response.data.detailHistory.length > 0) {
								var nameHistory = response.data.detailHistory;
								var nameHistoryStr = "";

								nameHistory.forEach(item => {
									nameHistoryStr +="\n" + item.companyName;
								});
								if (confirm('현재 고객사 이름이 아래 고객사에서 과거에 사용된 이름입니다.\n계속 진행하시겠습니까?\n'+nameHistoryStr)) {
									i.requiredCheck=true;
									i.companyNameDisabled=true;												
									i.$messageShow('등록이 가능한 고객사 입니다.', 'success');
								}
							} else {
								i.requiredCheck=true;
								i.companyNameDisabled=true;		
								i.$messageShow('등록이 가능한 고객사 입니다.', 'success');
							}						
						},
						function(e, i){
							console.log(e);
						}
			);					
		}		
	}
  }
}
</script>

<style scoped>
.black-bg {
  width: 100%; 
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  padding: 20px;
}
.white-bg {
  width: 400px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  transform: translate(-50%, 5%);
}
h4 {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(169, 168, 168);
}
.check {
  margin-left: 40px;
}
</style>

