
<template>    
  <transition name="fade" @closesModal="supportDetailModal = false" @update="update = true">    
    <SupportDetail :supportNames="supportNames" :supportDetailsKey="supportDetailsKey" :p_supportCategory="supportCategory" :isAdmin="isAdmin" :update="update" v-if="supportDetailModal" />
  </transition>
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"> 
    <h1 class="h2">기술지원 내역 - 통계
    </h1>
  </div>

  <!-- 검색 조건 -->
  <div class="container" v-if="isMobile == false">	    
    <input v-model="searchYear" class="form-control" type="text" placeholder="년 (YYYY)" @keyup.enter="changePage(1, this.pagination.pageSize)"  style="width:200px;"/>
    <Dropdown v-model="searchMonth" editable scroll-height="500px" :options="searchMonths" optionLabel="name" @blur="blurSearchMonth" placeholder="월 / 분기 / 반기 (한달 단위 검색은 입력 1~12)" @keyup.enter="changePage(1, this.pagination.pageSize)" class="w-full md:w-14rem" />  
    <Dropdown v-model="searchWeek" editable scroll-height="500px"  :options="searchWeeks" optionLabel="period" v-if="this.searchWeekTF" @blur="blurSearchWeek" placeholder="주차" @keyup.enter="changePage(1, this.pagination.pageSize)" class="w-full md:w-14rem"  />  
    <Dropdown v-model="supportName" editable scroll-height="500px"  :options="supportNames" optionLabel="name" placeholder="작업자" @keyup.enter="changePage(1, this.pagination.pageSize)" class="w-full md:w-14rem"  />  
    <Dropdown v-model="businessTypeName" editable scroll-height="500px" :options="businessTypes" optionLabel="label" placeholder="사업구분" @keyup.enter="changePage(1, this.pagination.pageSize)" class="w-full md:w-14rem"  />        
    <Dropdown v-model="entity" editable scroll-height="500px" :options="entitys" optionLabel="label" placeholder="요청주체" @keyup.enter="changePage(1, this.pagination.pageSize)" class="w-full md:w-14rem"  />  
    <Dropdown v-model="searchProdCode" editable scroll-height="500px" :options="prodCodes" optionLabel="label" placeholder="제품" @keyup.enter="changePage(1, this.pagination.pageSize)" class="w-full md:w-14rem"  />  
    <Dropdown v-model="searchStatusType" editable scroll-height="500px" :options="statusTypes" optionLabel="label" placeholder="지원상태" @keyup.enter="changePage(1, this.pagination.pageSize)" class="w-full md:w-14rem"  />  
    <div style="display:flex; justify-content: flex-end; width:520px;">
      <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText" style="margin-right:10px;" ></Button>
      <Button class="pi pi-times-circle" severity="success" @click="unsetSearchData" @mouseover="setHoverText('검색 초기화')" @mouseout="clearHoverText" :title="hoverText" style="margin-right:10px;"></Button>
      <Button class="pi pi-copy" @click="copyWeekString" style="background:black;margin-right:10px;" v-tooltip.top="{ value: '주간보고용', autoHide: false }"></Button>
      <Button class="pi" @click="exportXls($event)" style="background:#ffffff; display:inline-flex; justify-content:center; align-items:center;">      
        <img src="@/assets/excel.svg" alt="Excel Icon" style="width: 25px; height: 25px;" />
      </Button>
    </div>
  </div>   

  <div class="container" v-if="isMobile == false">	    
    <table>                       
      <td v-for="(value, key) in groupedCounts" :key="key" style="margin-right: 10px;">
        <Tag class="p-tag p-component p-tag-info" :style="tagStyle(key)">{{ key }}</Tag> 
        <Badge :value="value" severity="secondary" style="margin-right: 20px;background-color: #ffffff;color: #343a40;"></Badge>
      </td>
      <td v-if="isSearched"><b> 총 {{ pagination.total  }} 건 中 {{ pagination.size }} </b></td>
    </table>
  </div>
  
  <!-- 본문 -->
  <div class="container" v-if="isMobile == false">	    
      <table class="table table-bordered table-hover caption-top">
        <thead class="table-light">
          <tr class="table-primary">
            <th scope="col" v-for="item in header" :key="item" class="center">
              {{ item }}
          </th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr v-for="(row, i) in statistics" :key="i"  >
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.businessTypeName }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.companyName.length > 8 ? row.companyName.substring(0,8) + '...' : row.companyName }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.projectName.length > 8 ? row.projectName.substring(0,8) + '...' : row.projectName }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.serverType }}</td>            
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.supportProd }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.supportNames.split(',').length > 1 ? row.supportNames.split(',')[0] + " 외 " + (row.supportNames.split(',').length-1) + "명" : row.supportNames }}</td>            
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.supportCategory }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.supportCategoryDetail }}</td>            
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.visitDate }}</td>            
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.requestEntityLabel }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.supportStatus }}</td>
        </tr>
      </tbody>
    </table>
  </div> 
  <div class="container" v-if="isMobile == true">
    <Chip label="PC로 보세요." icon="pi pi-megaphone" />
    <a href="/biz" >
      <Button icon="pi pi-home" severity="secondary" style="width:2rem;height:2rem;"  />
    </a>
    <Chip label="👉보고용" />
    <Button icon="pi pi-copy" @click="copyWeekString" style="background:black;width:2rem;height:2rem;" />    
  </div>
  <div class="container">
      <LoadingBar :loading= "loading" v-if="loading == true"></LoadingBar>
      <Pagination :pagination="pagination" @page-change="changePage" v-if="isMobile == false"></Pagination>
      <mPagination :pagination="pagination" @page-change="changePage" v-if="isMobile == true"></mPagination>
      <Badge :value="pagination.total" severity="warning" v-if="isMobile == false" style="margin-top:20px"></Badge>
  </div>
</template>
  
<script>  
import Pagination from '@/components/Common/ClipPagination.vue'
import mPagination from '@/components/Common/mClipPagination.vue'
import LoadingBar from '@/components/Common/LoadingBar.vue'
import SupportDetail from '../Support/SupportDetail.vue'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

export default {
  name: 'StatisticsMain',
  data() {
    return {                
      header: ["사업구분","고객사", "프로젝트","서버구분","제품","작업자","지원방법","지원구분","작업일자","요청주체","지원상태"],        
      update: false,
      searchYear: new Date().getFullYear(),
      searchWeek: '',
      searchWeeks: [],
      searchWeekTF: false,
      searchMonth: "",
      searchMonths: [
                      {
                        name: '1/4분기',
                        code: 'Q1'
                      },
                      {
                        name: '2/4분기',
                        code: 'Q2'
                      },
                      {
                        name: '3/4분기',
                        code: 'Q3'
                      },
                      {
                        name: '4/4분기',
                        code: 'Q4'
                      },
                      {
                        name: '상반기',
                        code: 'H1'
                      },
                      {
                        name: '하반기',
                        code: 'H2'
                      },         
          ],
      currentPage: 1,        // 현재 페이지
      itemsPerPage: 10,      // 페이지당 항목 수
      total: 0,      // 페이지당 항목 수        
      pagination: {
            endRow: 0,
            hasNextPage: false,
            hasPreviousPage: false,
            isFirstPage: true,
            isLastPage: true,
            navigateFirstPage: 0,
            navigateLastPage: 0,
            navigatePages: 10,
            navigatepageNums: [],
            nextPage: 0,
            pageNum: 1,
            pageSize: this.isMobile ? 5 : 10,
            pages: 0,
            prePage: 0,
            size: 0,
            startRow: 0,
            total: 0
        },      
      hoverText: '',
      statistics: {},
      businessTypeName: '',
      businessTypes: {},
      isMobile : this.$isMobile(),
      supportName:'',
      supportNames:{},
      loading: false,
      groupedCounts: [],
      isSearched: false,    
      searchStatusType: '',   
      statusTypes: {}, 
      supportDetailModal: false,
      entity: '',
      entitys: {},
      searchProdCode: '',
      prodCodes: {},
    };
  },
  props: {
  },
  components: {      
    Pagination,
    mPagination,
    LoadingBar,
    SupportDetail,
  },
  created() {      
    this.$code(["BUSINESS_TYPE", "SUPPORT_STATUS", "ENTITY", "PROD_CODE"], false,
      function(result, i){          
            i.businessTypes = result.businessType;
            i.statusTypes = result.supportStatus;
            i.entitys = result.entity;
            i.prodCodes = result.prodCode;
          },
          function(e, i){
            console.log(e);
          }
    )
  },   
  watch: {    
    update(update) {      
      if (update == true) {        
        var pageSize = this.isMobile ? 5 : 10;
        this.changePage(this.currentPage, pageSize);
        this.update = false;
      }
    }
  }, 
  computed: {
  },
  mounted() {
    this.$nextTick(function() {
      this.getTeamAccount();    
    });
  },
  methods: {      
    // 페이지 변경 시 호출될 함수      
    changePage(newPage, pageSize) {      
        return new Promise((resolve, reject) => {
            this.currentPage = newPage;
            const paramData = {
                "pageNum": newPage,
                "pageSize": pageSize,
                "searchYear": this.searchYear,
                "searchMonth": this.searchWeek !== '' ? "" : this.searchMonth.code !== undefined ? this.searchMonth.code : this.searchMonth,
                "searchSupportName": this.supportName.name === undefined ? this.supportName : this.supportName.name,
                "searchBusinessType": this.businessTypeName.value === undefined ? "" : this.businessTypeName.value,
                "searchSearchStatusType": this.searchStatusType.label === undefined ? "" : this.searchStatusType.label,
                "startDate": this.searchWeek !== '' ? this.searchWeek.startDate : "",
                "endDate": this.searchWeek !== '' ? this.searchWeek.endDate : "",
                "requestEntity": this.entity.code === undefined ? "" : this.entity.code,
                "searchProdCode": this.searchProdCode.label === undefined ? "" : this.searchProdCode.label,
            };
            this.loading = true;

            this.$get('/biz/statistics/list', {
                    params: paramData
                },
                (response, i) => {
                    i.statistics = response.data.pagination.list;   // 기존 데이터에 추가
                    i.pagination = response.data.pagination;
                    i.statistics.forEach(stat => {
                      const entity = i.entitys.find(e => e.code === stat.requestEntity);
                      stat.requestEntityLabel = entity ? entity.label : null;
                    });

                    i.groupedCounts = i.getGroupedCounts(i.pagination.list);
                    const cnt = response.data.pagination.total;
                    i.isSearched = cnt > 0;

                    i.loading = false;
                    resolve();  // 성공 시 Promise를 resolve하여 완료 상태를 알림
                },
                (e, i) => {
                    console.log(e);
                    i.loading = false;
                    reject(e);  // 실패 시 Promise를 reject하여 오류 상태를 알림
                }
            );
        });
    },
    popupDetailModal(row){
        this.supportDetailModal = true;
        this.supportDetailsKey = row.supportDetailsKey;
    },    
    unsetSearchData(){
        this.searchMonth = "";
        this.supportName = "";          
        this.businessTypeName = "";
        this.searchWeek = '';
        this.searchWeekTF = false;
        this.searchYear = new Date().getFullYear();
        this.searchStatusType = '';
        this.pagination.pageSize = 10;          
        this.entity = '';
        this.searchProdCode = '';
    },
    setHoverText(text) {
      this.hoverText = text;
    },
    clearHoverText() {
      this.hoverText = '';
    },
    exportXls() {
            var paramData = {
              "pageNum": 1,
              "pageSize": 1000000,
              "searchYear": this.searchYear,
              "searchMonth": this.searchMonth.code != undefined ? this.searchMonth.code : this.searchMonth,
              "searchSupportName": this.supportName.name == undefined ? "" : this.supportName.name,
              "searchBusinessType": this.businessTypeName.value == undefined ? "" : this.businessTypeName.value,
            };

            this.$get('/biz/statistics/list', {
              params: paramData
            },
            (response) => {
              const data = response.data.pagination.list;                
              data.forEach(stat => {
                const entity = this.entitys.find(e => e.code === stat.requestEntity);
                stat.requestEntityLabel = entity ? entity.label : null;
              });
              const worksheet = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(data)));
              const workbook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

              const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
              const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
              const downloadFileName = '기술지원 통계'
              + (this.searchYear != "" ? "-"+this.searchYear+"년" : "")
              + (this.searchMonth.code != undefined ? this.searchMonth.code : this.searchMonth+"월")
              + (this.supportName.name == undefined ? "" : "-"+this.supportName.name)
              + (this.businessTypeName.value == undefined ? "" : "-"+this.businessTypeName.value)
              +'.xlsx';                
              saveAs(dataBlob, downloadFileName);
            },
            (error) => {
              console.log(error);
            });
    },
    getTeamAccount() {
      var paramData = {
            "teamCode": "TST"
      };     
      this.$get('/biz/account/teamlist', {
                  params: paramData
              },
                  function(response, i){
                    i.supportNames = response.data.teamResult;   //기존데이터에 추가                  
                  },
                  function(e, i){
                    console.log(e);
                  }
      );
    },
    blurSearchMonth(){
      this.searchWeek = '';                
      if(this.searchYear != '' && this.searchMonth != '' && typeof this.searchMonth == 'string') {
        this.searchWeekTF = true;
        let year = this.searchYear;
        let month = this.searchMonth;
        let weeks = this.getWeeksInMonth(year, month);
        this.searchWeeks = weeks;
      } else {
        this.searchWeekTF = false;
      }
    },
    blurSearchWeek(){
      this.searchYear = "";
    },
    getWeeksInMonth(year, month) {
      // 시작일은 해당 월의 1일
      let startDate = new Date(year, month - 1, 1);
      // 종료일은 다음 월의 0일 (현재 월의 마지막일)
      let endDate = new Date(year, month, 0);

      let weeks = [];
      let weekIndex = 1;
      let currentDate = startDate;

      // 시작일이 종료일 이전이거나 같을 때까지 반복
      while (currentDate <= endDate) {
        let weekStart = new Date(currentDate);
        let weekEnd = new Date(currentDate);

        // 주의 시작일을 해당 주의 첫째 날 (월요일)로 설정
        weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 5);
        // 주의 종료일을 해당 주의 마지막 날 (일요일)로 설정
        weekEnd.setDate(weekEnd.getDate() - weekEnd.getDay() + 11);

        // 주차를 추가
        weeks.push({
          week: weekIndex,
          startDate: this.formatDate(weekStart),
          endDate: this.formatDate(weekEnd),
          period: this.formatDate(weekStart) +' ~ '+this.formatDate(weekEnd)
        });

        // 다음 주로 이동
        currentDate.setDate(currentDate.getDate() + 7);
        weekIndex++;
      }

      return weeks;
    },

    // 날짜 형식을 'YYYY/MM/DD'로 포맷하는 함수
    formatDate(date) {
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      return `${year}/${month}/${day}`;
    },
    getGroupedCounts(arr){
      const gCnt = arr.reduce((acc, curr) => {
        if (acc[curr.supportCategory]) {
          acc[curr.supportCategory]++;
        } else {
          acc[curr.supportCategory] = 1;
        }
        return acc;
      }, {});

      return gCnt;
    },
    tagStyle(status){
      if(status == '유선') {
          return 'margin-right: 5px;background-color: #f59e0b';
      } else if(status == '원격') {
          return 'margin-right: 5px;background-color: #dc3545;';            
      } else if(status == '방문') {
          return 'margin-right: 5px;background-color: #198754;';
      }else {
          return 'margin-right: 5px;background-color: #0060bf';
      }
    },
    async copyWeekString() {       
      this.searchWeekTF = false;
      const today = new Date();
      const month = today.getMonth() + 1;  // 월은 0부터 시작하므로 1을 더해야 실제 월 숫자가 나옴
      
      const period = this.getDateRangeForLastFridayToThisThursday();
      const sDate = this.getDateRangeForLastFridayToThisThursday().split(' ~ ')[0];
      const eDate = this.getDateRangeForLastFridayToThisThursday().split(' ~ ')[1];        
      this.searchMonth = month;
      this.searchWeek = {startDate:sDate,endDate:eDate,period:period};
      
      const c_supportName = this.$store.getters.getUserEmail; 
      this.supportNames.forEach(function(supportName2, index) {        
        if(c_supportName === supportName2.userEmail){          
            self.supportName = supportName2.name;          
          }
      });

      this.supportName = self.supportName;
      
      await this.changePage(1, 99999);

      try {            
          const supportTypes = {
              "설치": { total: 0, 방문: 0, 원격: 0, 메일: 0, 유선: 0 },
              "교육": { total: 0, 방문: 0, 원격: 0, 메일: 0, 유선: 0 },
              "미팅": { total: 0, 방문: 0, 원격: 0, 메일: 0, 유선: 0 },
              "기술지원": { total: 0, 방문: 0, 원격: 0, 메일: 0, 유선: 0 },
              "기능패치": { total: 0, 방문: 0, 원격: 0, 메일: 0, 유선: 0 },
              "버그패치": { total: 0, 방문: 0, 원격: 0, 메일: 0, 유선: 0 },
              "비상주": { total: 0 },
          };

          // statistics 데이터 순회하여 카운트 증가
          for (const record of this.statistics) {
              if (record.supportStatus === "완료") {
                  const category = record.supportCategoryDetail;
                  const type = record.supportCategory;

                  if (supportTypes[category]) {
                      supportTypes[category].total++;
                      supportTypes[category][type]++;
                  }
              } else {
                if(record.supportCategory == "비상주") {                  
                  supportTypes[record.supportCategory].total++;
                }
              }
          }

          // 출력 문자열 생성
          let copyText = "";
          copyText += this.searchWeek.period + " " + self.supportName + "\n";
          for (const [key, value] of Object.entries(supportTypes)) {
              if(key == "비상주"){
                copyText += `${key} : ${value.total}`;
              } else {
                copyText += `${key} : ${value.total} (방문 ${value.방문} / 원격 ${value.원격} / 메일 ${value.메일} / 유선 ${value.유선})\n`;
              }
              
          }

          // 클립보드에 복사
          await navigator.clipboard.writeText(copyText);
          alert("클립보드에 복사되었습니다!");
      } catch (error) {
          console.error("복사에 실패했습니다:", error);
      }
  },
    getDateRangeForLastFridayToThisThursday() {
      const today = new Date();

      // 오늘의 요일 가져오기 (0: 일요일 ~ 6: 토요일)
      const dayOfWeek = today.getDay();

      // 지난주 금요일 계산 (오늘이 금요일일 경우 전주 금요일로 처리)
      const lastFriday = new Date(today);
      lastFriday.setDate(today.getDate() - ((dayOfWeek + 1) % 7) - 1);

      // 이번 주 목요일 계산
      const thisThursday = new Date(lastFriday);
      thisThursday.setDate(lastFriday.getDate() + 6);

      // yyyy/mm/dd 형식으로 날짜 포맷
      //const formatDate = (date) => date.toISOString().slice(0, 10).replace(/-/g, '/');
      const formatDate = (date) => date.toLocaleDateString('ko-KR', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\.\s/g, '/').replace(/\.$/, '');
      const startDate = formatDate(lastFriday);
      const endDate = formatDate(thisThursday);

      return `${startDate} ~ ${endDate}`;
    },    
  },    
};
</script>

<style lang="css" scoped>
.container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  justify-content: center
}
.container > * {
  margin-right: 10px;
  font-size: 15px;
}
.container > *:last-child {
  margin-right: 0;
}
.container .selects {
  display: flex;
}
.container .selects select {
  width: 120px;
  margin-right: 10px;
}
.container .selects select:last-child {
  margin-right: 0;
}

.center {
  text-align: center;
}
.container .btn {
  width: 120px;
  height: 50px;
  font-weight: 700;
  flex-shrink: 0;
}
@media only screen and (max-width: 480px) {
    .container {
      display: block;
    }
    .selects {
      margin-top: 10px;
    }
    .btn {
      margin-top: 10px;
    }
}

th{
  text-align: center;
}

.pi{
  min-width: 56px;
  width: 56px;
  min-height: 50px;
  height: 50px;
}
</style>