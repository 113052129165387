<template>
  <transition name="fade" @closesModal="companyInfoUpdateModal = false" @update="update = true">
    <companyInfoUpdate :detailsCompanySeq="detailsCompanySeq" :update="update" v-if="companyInfoUpdateModal" />
  </transition>

  <transition name="fade" @closesModal="companyInfoRegistModal = false" @update="update = true">
    <companyInfoRegist v-if="companyInfoRegistModal" />
  </transition>

  <transition name="fade" @closesModal="formerCompanyModal = false" @update="update = true">
    <formerCompany  :detailsFormerCompany="detailsFormerCompany" v-if="formerCompanyModal" />
  </transition>

  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">고객사 관리</h1>
  </div>

  <!-- 검색 조건 -->
  <div class="container" v-if="isMobile == false">
    <SelectButton v-model="selectedOption" :options="options" optionDisabled="constant" optionLabel="name" aria-labelledby="basic" @click="changePage(1, this.pagination.pageSize)" 
    style="display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap;" />    
    <input v-model="searchText" class="form-control" type="text" placeholder="고객사명"  @keyup.enter="changePage(1, this.pagination.pageSize)" />    
    <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText"></Button>		
    <Button class="pi pi-pencil" severity="warning" @mouseover="setHoverText('등록')" @mouseout="clearHoverText" :title="hoverText" @click="companyInfoRegistModal = true"></Button>
  </div>
  <div class="container" v-if="isMobile == true">
    <div class="d-flex">
    <input v-model="searchText" class="form-control" type="text" placeholder="고객사명"  @keyup.enter="changePage(1, this.pagination.pageSize)" />
      <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText"></Button>
      <Button class="pi pi-pencil" severity="warning" @mouseover="setHoverText('등록')" @mouseout="clearHoverText" :title="hoverText" @click="companyInfoRegistModal = true"></Button>
    </div>
   </div>
  

    <!-- 본문 -->
  <div class="container" v-if="isMobile == false">	    
	  <table class="table table-bordered table-hover caption-top" >
	    <thead class="table-light">
	      <tr class="table-primary">
	        <th scope="col" v-for="item in header" :key="item" :style="{ width: headerWidths[item]}" class="center">
	          {{ item }}
	        </th>
	      </tr>
	    </thead>
	    <tbody class="table-group-divider">
	      <tr v-for="(row, i) in company" :key="i" style="cursor: pointer;">
	        <td class="left" @click="companyInfoUpdateModal = true;  detailsCompanySeq = row.companySeq;">{{ row.companyName }}</td>
	        <td class="center"  @click="companyInfoUpdateModal = true;  detailsCompanySeq = row.companySeq;">{{ row.businessTypeName }}</td>
          <td>
            <div :style="stockClass(row.companyNames.split(',').length-1 > 0 ? row.companyNames.split(',').length-1 : 0)" @click="row.companyNames.split(',').length-1 > 0 && popupFormerCompanyModal(row.companyNames)">
              {{ row.companyNames.split(',').length-1 > 0 ? row.companyNames.split(',').length-1 : "" }}
            </div>
          </td>
	        <td  @click="companyInfoUpdateModal = true;  detailsCompanySeq = row.companySeq;">{{ row.homepageUrl }}</td>	        
          <td class="center" v-if="isAdmin == 'Y'" style="cursor: pointer; text-overflow:ellipsis;"><Checkbox v-model="row.useYn" trueValue="Y" @change="onCheckFocus(row)" invalid binary /></td>   
	      </tr>
	    </tbody>
	  </table>
  </div>

    <!-- 본문 모바일 -->
  <div class="container" v-if="isMobile == true">
  	<Accordion :activeIndex="0">
	    <AccordionTab v-for="(row, i) in company" :key="i" :header="row.companyName + ' - ' + row.businessTypeName">
	        <p>고객사 : {{ row.companyName }}</p>
	        <p>사업구분 : {{ row.businessTypeName }}</p>
	        <p v-if="row.homepageUrl != ''">홈페이지 : {{ row.homepageUrl }}</p>
	        <button
		      class="btn btn-secondary"
		      @click="companyInfoUpdateModal = true; detailsCompanySeq = row.companySeq;">
		      수정
		    </button>
	    </AccordionTab>
	  </Accordion>
  </div>
  <div class="container">
	  <Pagination :pagination="pagination" @page-change="changePage" v-if="isMobile == false" ></Pagination>
	  <mPagination :pagination="pagination" @page-change="changePage" v-if="isMobile == true"></mPagination>
 </div>
</template>

<script>
import companyInfoUpdate from './Update.vue'
import companyInfoRegist from './Regist.vue'
import Pagination from '@/components/Common/ClipPagination.vue'
import mPagination from '@/components/Common/mClipPagination.vue'
import formerCompany from './FormerCompany.vue'

export default {
  name: 'CompanyMain',
  data() {
    return {
      companyInfoUpdateModal: false,
      companyInfoRegistModal: false,
      formerCompanyModal: false,
      company: {},
      detailsFormerCompany: "",
      header: ["고객사명", "사업구분", "과거고객사명", "홈페이지"],
      headerWidths: {'고객사명':'40%', '사업구분':'10%','과거고객사명':'10%','홈페이지':'30%','사용여부':'10%'}, // 각 열의 넓이
      detailsCompanySeq: "",
      update: false,
      searchText: '',
      currentPage: 1,        // 현재 페이지
      itemsPerPage: 10,      // 페이지당 항목 수
      total: 0,      // 페이지당 항목 수
      isMobile : this.$isMobile(),
      pagination: {
    		endRow: 0,
		    hasNextPage: false,
		    hasPreviousPage: false,
		    isFirstPage: true,
		    isLastPage: true,
		    navigateFirstPage: 0,
		    navigateLastPage: 0,
		    navigatePages: 10,
		    navigatepageNums: [],
		    nextPage: 0,
		    pageNum: 1,
		    pageSize: 10,
		    pages: 0,
		    prePage: 0,
		    size: 0,
		    startRow: 0,
		    total: 0        
    	},
      hoverText: '',
      isAdmin: 'N',
      selectedOption: { name: '이름순', value: '이름순', constant: false }, 
      options: [
                { name: '이름순', value: '이름순', constant: false },
                { name: '수정일순', value: '수정일순', constant: false }
            ],
    };
  },
  components: {
    companyInfoUpdate,
    companyInfoRegist,
    formerCompany,
    Pagination,
    mPagination,
  },
  created() {    
     this.$post(`/biz/account/${this.$store.getters.getUserEmail}/team/get`, null,
              function(response, i){
                i.isAdmin = response.data.adminYn;                
                i.changePage(1, i.isMobile ? 5 : 10)
              },
              function(e, i){
              	console.log(e);
              }      
    );
    
  }, watch: {
    update(update) {
      if (update == true) {
        window.location.reload(true);
      }
    }
  },
  computed: {
  },
  mounted(){
    this.onResize();
    window.addEventListener('resize',this.onResize,{passive:true});
  },
  beforeDestory(){
    if(typeof window === 'undefined') return;
    window.removeEventListener('resize',this.onResize,{passive:true});
  },
  methods: {
    // 페이지 변경 시 호출될 함수
    changePage(newPage, pageSize) {            
      this.currentPage = newPage;

      var paramData = {
    	"pageNum": newPage,
      "pageSize": pageSize,
      "companyName": this.searchText,
      "isAdmin":this.isAdmin,
      "sortOption":this.selectedOption.value,
	    };
	    
    this.$get('/biz/company/list', {
	            	params: paramData
	       		 },
                function(response, i){
                	i.company = response.data.pagination.list;   //기존데이터에 추가
                	i.pagination = response.data.pagination;
                  i.header = i.isAdmin == 'Y' ? i.header = ["고객사명", "사업구분", "과거고객사명", "홈페이지", "사용여부"] : i.header = ["고객사명", "사업구분", "과거고객사명", "홈페이지"]          
                },
                function(e, i){
                	console.log(e);
                }
     );
     
    },
    onResize(){
      this.isMobile = window.innerWidth < 600
    },
    setHoverText(text) {
      this.hoverText = text;
    },
    clearHoverText() {
      this.hoverText = '';
    },
    stockClass(data) {      
            return ['width: 20px', 'height: 20px', 'border-radius: 50%', 'text-red-900', 'display: flex', 'justify-content: center', 'align-items: center', 'margin: 0 auto',
                data > 0 && data < 5 ? 'background-color : var(--blue-100)!important' : data > 5 ? 'background-color : var(--teal-100)!important' : ''];                  
    },
    popupFormerCompanyModal(data){    
      this.formerCompanyModal = true; 
      this.detailsFormerCompany = data;
    
    },
    onCheckFocus(row){      
      var self = this;      
      if(this.isAdmin == 'Y'){      
        var param = {	          
            "companySeq" : row.companySeq,
            "modifierEmail" : this.$store.getters.getUserEmail,            
            "useYn" : row.useYn == false ? 'N' : 'Y'
          };
        this.$post(`/biz/company/mod/use-yn`, param,
                function(response, i){
                  self.$messageShow('수정되었습니다.', 'success');                 
                },
                function(e, i){
                  console.log(e);
                }
        );
      } else {
        this.$messageShow('권한이 없습니다.(관리자만 가능)', 'warn');
        if(row.useYn == false) {
          row.useYn = 'Y';
        } else {
          row.useYn = '';
        }
      }    
    }, 
  },
};
</script>

<style lang="css" scoped>
.container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  justify-content: center
}
.container > * {
  margin-right: 10px;
  font-size: 15px;
}
.container > *:last-child {
  margin-right: 0;
}
.container .selects {
  display: flex;
}
.container .selects select {
  width: 120px;
  margin-right: 10px;
}
.container .selects select:last-child {
  margin-right: 0;
}

.center {
  text-align: center;
}
.container .btn {
  width: 120px;
  height: 50px;
  font-weight: 700;
  flex-shrink: 0;
}
@media only screen and (max-width: 480px) {
	.container {
	  display: block;
	}
	.selects {
	  margin-top: 10px;
	}
	.btn {
	  margin-top: 10px;
	}
}

th{
  text-align: center;
}
.pi{
  min-width: 56px;
  width: 56px;
  min-height: 50px;
  height: 50px;
}
/* .btn {
  width: 200px;
  height: 200px;
  display: table-cell;
  vertical-align: middle;
  text-align:center;
} */
</style>