<template>

	<Dialog v-model:visible="visible" maximizable modal header="기술지원 이력(2024) 상세" :style="{ width: modalWidth }" @update:visible="$emit('closesModal'); visible = false;">		
		  <div class="flex flex-wrap justify-content-left gap-2 mb-2">
			  <Divider align="left" type="solid">
				<b>고객사</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.companyName" disabled />
			</p>
			
			<Divider align="left" type="solid">
				<b>프로젝트</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.projectName" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>서버구분</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.serverType" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>제품명</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.supportProdName" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>웹버전</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.versionWeb" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>엔진버전</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.versionEngine" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>엔지니어</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.supportName" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>팀</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.team" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>지원방법</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.supportCategory" disabled />
			</p>		
			
			<Divider align="left" type="solid">
				<b>지원구분</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.supportCategoryDetail" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>방문일자</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.visitDate" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>처리내용</b>
			</Divider>
			<p>
				<Textarea v-model="details.responseDetails" autoResize rows="4" disabled :style="{ 'font-family': 'Consolas,monaco,monospace' ,'min-width': '100%'  }"/>
			</p>

			<Divider align="left" type="solid">
				<b>영업대표</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.salesRepresentative" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>지역구분</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.region" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>CodeSeries</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.codeSeries" disabled />
			</p>
			
			<Divider align="left" type="solid">
					<b>설치문서(url)</b>
			</Divider>
			<p class = "d-flex">						
				<input type="text" class="form-control" v-model="details.supportDetailsInstallDoc" disabled />
				<Button v-if="isValidUrl" icon="pi pi-search-plus" @click="openUrl(details.supportDetailsInstallDoc)" style="background-color: black;border:black;"  />
			</p>

			<Divider align="left" type="solid">
				<b>번들여부</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.partnerYN" disabled />
			</p>
		  </div>
		  <template #footer>
			<Button label="닫기" icon="pi pi-times" @click="$emit('closesModal'); visible = false;" text />			
		</template>		
	  </Dialog>
	</template>
	
	<script>
	import _ from "lodash";
	export default {
	  name: 'SupportOldInfoUpdateModal',
	  data(){
		return{
		  details: {},	 
		  visible: true,
		  modalWidth: this.$isMobile() ? '100vw' : '35vw',
		  isValidUrl:false,
		}
	  },
	  props: {		 
		  detailsSupportOldSeq: Number,
	  },
	  components: {
	  },
	  created(){			
		
	  },
	  mounted(){
			this.$nextTick(function() {
				this.setSupportOldInfo();
				  
			});
	  },
	  methods: {
		setSupportOldInfo(){	
			this.$get(`/biz/supportold/2024/get`, {
				params: {									
						seq : this.detailsSupportOldSeq,
					}
				},
			function(response, i){
				i.details = response.data.detail;
				i.checkURL(i.details.supportDetailsInstallDoc);
			},
			function(e, i){
				console.log(e);
			}
		  );		  
		},	
		openUrl(url) {
			window.open(url, '_blank');
		},	
		checkURL(str){			
			const regex = /^(http|https):\/\/[\w-]+(\.[\w-]+)+[/#?]?.*$/;
			this.isValidUrl = regex.test(str);
    		return this.isValidUrl;
		},
	  }
	}
	</script>	
	
	<style scoped>
	.black-bg {
	  width: 100%; 
	  height: 100%;
	  background: rgba(0,0,0,0.5);
	  position: fixed;
	  padding: 20px;
	}
	.white-bg {
	  width: 400px;
	  background: white;
	  border-radius: 8px;
	  padding: 20px;
	  margin: 0 auto;
	  transform: translate(-50%, 5%);
	}
	h4 {
	  padding-bottom: 10px;
	  margin-bottom: 20px;
	  border-bottom: 1px solid rgb(169, 168, 168);
	}
	.check {
	  margin-left: 40px;
	}
	</style>
	
	